#popup-purchase-wrapper {
    background: #fff;
    max-width: 500px;
    width: 90%;
    margin: 0 auto;
    min-height: 100px;
    position: relative;
}
#popup-purchase-wrapper .popup-heading {
    text-align: left;
    background: transparent;
    color: #1c1c1c;
    font-size: 21px;
    border-bottom: 1px solid #dfe4eb;
    padding: 0 25px 0 25px;
    position: relative;
    min-height: 52px;
    line-height: 52px;
    overflow: hidden;
}
#popup-purchase-wrapper .mfp-close {
    text-indent: -9999px;
    width: 60px;
    height: 52px;
    background: #fff url(close.png) no-repeat;
    right: 0;
    top: 0;
    position: absolute;
    z-index: 2;
    cursor: pointer !important;
    outline: none;
    border: 0;
}
#popup-purchase-wrapper .mfp-close:hover {
    background-position: 0 -60px;
}


#popup-purchase-wrapper .popup-footer {
    padding: 17px 25px 17px 25px;
    border-top: 1px solid #dfe4eb;
    background-color: #f2f6f9;
    position: relative;
    display: inline-block;
    width: 100%;
    height: 100%;
    vertical-align: bottom;
}
#popup-purchase-wrapper .popup-footer button {
	 float: right;
	display: none;
    height: 32px;
    line-height: 30px;
    color: #000;
    padding: 0 25px;
    cursor: pointer;
    font-size: 12px;
    display: inline-block;
    border: 1px solid #c5cdd8;
    box-shadow: 0 1px 1px rgba(0, 0, 0, .1);
    background: #f2f6f9;
    border-radius: 2px;
    outline: none;
    text-align: center;
}
#popup-purchase-wrapper .popup-footer button:hover {
    box-shadow: 0 0px 1px rgba(0, 0, 0, .1);
}
#popup-purchase-wrapper .popup-footer a {
    float: left;
    border: 1px solid #f06b10;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
    background: #ff8001;
    color: #fff;
    font-size: 13px;
    height: 33px;
    line-height: 33px;
    padding: 0 30px;
    cursor: pointer;
    display: inline-block;
    border-radius: 2px;
    text-align: center;
}
#popup-purchase-wrapper .popup-footer #popup-checkout-none {
    opacity: 0.5;
    background: #F64747;
    border: 1px solid #D64541;
}
#popup-purchase-wrapper .popup-footer #popup-checkout-none:hover {
    background: #F64747;
    cursor: not-allowed;
}
#popup-purchase-wrapper .popup-footer a:hover {
    background: #ff9601;
}
#popup-purchase-wrapper .popup-center {
    background: #fff;
    padding: 25px;
    overflow: auto;
}
#popup-purchase-wrapper .left {
    float: left;
    width: 60%;
}
#popup-purchase-wrapper .right {
    float: right;
}
#popup-purchase-wrapper .right img {
    border: 1px solid #dfe4eb;
}
#popup-purchase-wrapper .right .sub-images {
    display: block;
    text-align: center;
    margin-top: 10px;
    max-width: 150px;
}
#popup-purchase-wrapper .right .sub-images > span label {
    border-radius: 10px;
    width: 10px;
    height: 10px;
    background: #bbb;
    display: inline-block;
}
#popup-purchase-wrapper .right .sub-images input {
    display: none;
}
#popup-purchase-wrapper .right .sub-images input[type=radio]:checked + label {
    background: #e5e5e5;
}
#popup-purchase-wrapper .right .sub-images > span + span {
    margin-left: 5px;
}
#popup-purchase-wrapper .right .sub-images > span label:hover {
    cursor: pointer;
    background: #e5e5e5;
}
#popup-purchase-wrapper .product-name {
    font-size: 18px;
    margin-bottom: 15px;
}
#popup-purchase-wrapper .product-price {
    font-size: 15px;
}
#popup-purchase-wrapper .product-description {
    max-height: 120px;
    overflow-y: auto;
    margin: 15px 0;
}
#popup-purchase-wrapper .payment-info {
    clear: both;
}
#popup-purchase-wrapper .info-heading {
    font-size: 15px;
    display: block;
    color: #000;
    text-align: left;
}
#popup-purchase-wrapper .info-heading-2 {
    font-size: 15px;
    display: block;
    color: #000;
    text-align: left;
    margin: 5px 0 10px 0;
}
#popup-purchase-wrapper .payment-info > div {
    width: 100%;
    display: inline-block;
}
#popup-purchase-wrapper .payment-info > div + div {
    margin-top: 10px;
}
#popup-purchase-wrapper .payment-info > div label {
    font-size: 12px;
    line-height: 12px;
    color: #666;
    font-weight: bold;
    margin-bottom: 2px;
}
#popup-purchase-wrapper .payment-info > div label span {
    color: #f00;
}
#popup-purchase-wrapper .payment-info > div input, #popup-purchase-wrapper .payment-info > div textarea {
    border: 1px solid #dfe4eb;
    padding: 5px 10px;
    background: #fff;
    outline: none;
    color: #666;
    font-size: 12px;
    width: 100%;
}
#popup-purchase-wrapper .payment-info > div textarea {
    min-height: 70px;
    resize: vertical;
}
.payment-quantity {
    position: relative;
}
.payment-quantity label {
    display: block;
    font-size: 12px;
    line-height: 12px;
    color: #666;
    font-weight: bold;
    margin-bottom: 4px;
}
.payment-quantity .number {
    border: 1px solid #c5cdd8;
    display: inline-block;
    vertical-align: middle;
    position: relative;
    margin-bottom: 5px;
}
.payment-quantity .frame-change-count {
    width: 116px;
}
.payment-quantity .btn-plus button {
    width: 32px;
    height: 32px;
    cursor: pointer;
    display: inline-block;
    background: #f2f6f9;
    border: 0;
    outline: none;
    line-height: 5px;
    text-align: center;
}
.payment-quantity .btn-minus button {
    width: 32px;
    height: 32px;
    cursor: pointer;
    display: inline-block;
    background: #f2f6f9;
    border: 0;
    outline: none;
    text-align: center;
    line-height: 0;
}
.payment-quantity .btn-minus button span {
    width: 14px;
    height: 2px;
    background: #f2f6f9 url(plus-minus.png) no-repeat;
    background-position: -20px 0px;
    display: inline-block;
}
.payment-quantity .btn-plus button span {
    width: 14px;
    height: 14px;
    background: #f2f6f9 url(plus-minus.png) no-repeat;
    background-position: 0 0px;
    display: inline-block;
}
.payment-quantity .btn-plus {
    float: right;
}
.payment-quantity .btn-minus {
    float: left;
}
.payment-quantity input.plus-minus {
    border-top: 0;
    border-bottom: 0;
    border-left: 1px solid #c5cdd8;
    border-right: 1px solid #c5cdd8;
    background: #fff;
    width: 52px;
    height: 32px;
    position: absolute;
    left: 32px;
    top: 0;
    outline: none;
    font-size: 18px;
    text-align: center;
}
.masked {
    position: absolute;
    z-index: 10000;
    width: 100%;
    height: 100%;
    background: #eee;
    opacity: 0.4;
    left: 0;
    top: 0;
}
.masked_loading {
    background: url(ring-alt.svg) no-repeat center center;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 10001;
    top: 0;
    left: 0;
}
.popup-purchase-button {
    text-align: center;
}
.popup-purchase-button button {
    width: 100% !important;
}
@media (min-width: 320px) and (max-width: 532px) {
    #popup-purchase-wrapper .popup-heading {
        font-size: 14px;
    }
    #popup-purchase-wrapper .popup-footer button {
        width: 100%;
    }
    #popup-purchase-wrapper .popup-footer a {
        width: 100%;
        margin-top: 5px;
    }
    #popup-purchase-wrapper .right {
        width: 100%;
        text-align: center;
    }
    #popup-purchase-wrapper .left {
        width: 100%;
    }
}