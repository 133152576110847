.magnify {
  position: relative;
  display: inline-block;
}
.magnify .magnify-lens {
  /* Create the magnifying lens */
  position: absolute;
  width: 230px;
  height: 230px;
  border-radius: 100%;
  /* Multiple box shadows to achieve the glass lens effect */
  -webkit-box-shadow: 0 0 0 7px rgba(255, 255, 255, 0.85),
    0 0 7px 7px rgba(0, 0, 0, 0.25),
    inset 0 0 40px 2px rgba(0, 0, 0, 0.25);
  box-shadow: 0 0 0 7px rgba(255, 255, 255, 0.85),
    0 0 7px 7px rgba(0, 0, 0, 0.25),
    inset 0 0 40px 2px rgba(0, 0, 0, 0.25);
  /* Hide the mouse pointer */
  cursor: none;
  /* Hide the lens by default */
  display: none;
  /* Place the lens on top of other elements */
  z-index: 100;
}
.magnify .magnify-lens.loading {
  background: #333 !important;
  opacity: .75;
}
.magnify .magnify-lens.loading:after {
  /* Loading text */
  position: absolute;
  top: 45%;
  left: 0;
  width: 100%;
  color: #fff;
  content: 'Loading...';
  font: italic normal 16px/1 Calibri, sans-serif;
  text-align: center;
  text-shadow: 0 0 2px rgba(51, 51, 51, .8);
  text-transform: none;
}