#kit-series .image, .sku, .model, thead .price_special, thead .input_qty, thead .total, .btn_buy_text, .btn_buy_stock {
    text-align: center;
}#kit-series .image {
    width: 67px;
    nax-width: 67px;
}#kit-series tbody .total, #kit-series tbody .price_special {
    width: 80px;
    nax-width: 80px;
    text-align: right;
}
.series-options, .modal-title, .series-options .modal-body .form-group {
    text-align: left;
}
#kit-series thead .input_qty {
    width: 73px;
}
#kit-series tbody .input_qty {
    padding: 8px 0;
}
#kit-series .input_qty input {
    display: inline-block;
    vertical-align: middle;
    width: 38px;
    margin: 0 0px 0 5px;
    padding: 0;
    text-align: center;
    font-size: 1.0em;
    border-radius: 3px 0 0 3px;
}#kit-series .input_qty input + span {
    display: inline-block;
    vertical-align: top;
    margin: 0px 0 0 -4px;
    overflow: hidden;
}#kit-series .input_qty input + span .btn {
    display: block;
    min-width: 25px;
    width: 8px;
    height: 17px;
    line-height: 17px;
    margin: 0;
    padding: 0;
    font-size: 0.6em !important;
    color: #777;
}#kit-series .input_qty input + span .btn:last-child {
    border-top: none;
    border-radius: 0 0 3px 0;
}#kit-series thead {
    background-color: #f5f5f5;
}#kit-series td {
    vertical-align: middle;
}.old-price {
    text-decoration: line-through;color: red;
}
#kjseries {
    display: none;
    overflow-x: scroll;
}
big {
    font-size: 12px;
}
.kjseries_type_images img {
    margin-left:10px;
    margin-right:10px;
}

#kjseries .img-responsive {
    margin:0 auto;
}

/* Cat - slider */
.product-list .kj-product-thumb .owl-item {
    width: auto !important;
}
.product-layout .image + div h4 + #kit-slider-cat, .product-layout .image + caption > #kit-slider-cat, .product-layout .image + div h4 + p + #kit-slider-cat,
.product-layout .image + div h4 + #kit-list-cat, .product-layout .image + caption > #kit-list-cat, .product-layout .image + div h4 + p + #kit-list-cat,
.product-layout .image + div h4 + #kit-info-cat, .product-layout .image + caption > #kit-info-cat, .product-layout .image + div h4 + p + #kit-info-cat {
    padding: 0 !important;
}
.image + #kit-slider-cat, .image + div > #kit-slider-cat,
.image + #kit-list-cat, .image + div > #kit-list-cat,
.image + #kit-info-cat, .image + div > #kit-info-cat,
.product-layout.product-list  .image + div h4 + #kit-slider-cat{
    padding: 0 20px !important;
}
.product-list .product-thumb .kjseries_cat {
    margin-left: 240px;
}
.product-list .product-thumb .caption .kjseries_cat {
    margin-left: 0;
}
.image + div > #kit-slider-cat .slick-dotted.slick-slider {
    margin-bottom: 0;
}
#kit-slider-cat .owl-wrapper-outer, .kit-slider-cat .owl-wrapper-outer {
    border: none;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -o-border-radius: 0;
    border-radius: 0;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    -o-box-shadow: none;
    box-shadow: none;
}

.kjseries_cat .owl-carousel {
    margin: 0 0 10px;
}
.checktoogle {
    cursor: pointer;
    width: 12px;
    height: 20px;
    position: absolute;
}
.kjowl_category .owl-item .kjseries-category-item {
    display: inline-block;
}
.kjseries_cat .owl-item > div {
    border: 2px solid rgba(0, 0, 0, 0);
    cursor: pointer;
}
#kit-attr-cat
{
    overflow-x: scroll;
}
#kit-slider-cat .owl-prev {
    left: -18px;
    top: 35%;
}
#kit-slider-cat .owl-next {
    right: -18px;
    top: 35%;
}
#kit-slider-cat .owl-carousel .owl-buttons div i {
    font-size: 20px;
}
#kit-slider-cat .owl-carousel .owl-buttons div {
    opacity: 0.7;
}

.kit-cat .btn {
    padding: 2px 5px !important;
    margin: 3px 0;
    border:2px solid #cccccc;
    border-radius: 0;
}
.kit-cat .selected, .kjseries_cat .owl-item .kjseries-category-item.selected {
    border:2px solid #23a1d1 !important;
}
#kit-info-cat .btn.kjseries-category-item > img + br {
    display: block;
}

#kit-list-cat, .kjseries_type_images,
.kjowl_product .class-qty, .kjseries_type_images .class-qty {
    display: inline-block;
}
.kit-list {
    float: left;
    padding-right: 20px;
    height: 50px;
}
.kit-list .dropdown.open {
    position: absolute;
}
.kit-list .btn {
    padding: 3px 8px 2px 10px;
}

.kjowl_product .kj-item, .kjseries_type_images .kj-item {
    padding: 5px 8px;
}

.kjowl_product .kj-item .class-qty input.form-control,
.kjseries_type_images .kj-item .class-qty input.form-control {
    display: inline-block;
    width: 40px;
    height: 29px;
    margin-bottom: 5px;
}
.kjowl_product .kj-item .class-qty input.form-control + span i.fa-plus,
.kjseries_type_images .kj-item .class-qty input.form-control + span i.fa-plus {
    float: right;
}
.kjowl_product .kj-item .class-qty input.form-control + span i.fa-minus,
.kjseries_type_images .kj-item .class-qty input.form-control + span i.fa-minus {
    float: left;
}

.kj-product-thumb-with-select .caption p,
.product-thumb.kj-product-thumb-with-select .price {display: none;}

.kjseries_type_images .kj-item {
    margin: 2px;
    border: 1px solid #cccccc;
}
.kjseries_type_images .kj-item:hover {
    box-shadow: 0 0 5px #E0E0E0;
}