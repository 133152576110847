/*! nouislider - 8.5.1 - 2016-04-24 16:00:30 */
.noUi-target, .noUi-target * {
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-ms-touch-action: none;
	touch-action: none;
	-ms-user-select: none;
	-moz-user-select: none;
	user-select: none;
	-moz-box-sizing: border-box;
	box-sizing: border-box
}

.noUi-target {
	position: relative;
	direction: ltr
}

.noUi-base {
	width: 100%;
	height: 100%;
	position: relative;
	z-index: 1
}

.noUi-origin {
	position: absolute;
	right: 0;
	top: 0;
	left: 0;
	bottom: 0
}

.noUi-handle {
	position: relative;
	z-index: 1
}

.noUi-stacking .noUi-handle {
	z-index: 10
}

.noUi-state-tap .noUi-origin {
	-webkit-transition: left .3s, top .3s;
	transition: left .3s, top .3s
}

.noUi-state-drag * {
	cursor: inherit!important
}

.noUi-base, .noUi-handle {
	-webkit-transform: translate3d(0,0,0);
	transform: translate3d(0,0,0)
}

.noUi-horizontal {
	height: 18px
}

.noUi-horizontal .noUi-handle {
	width: 34px;
	height: 28px;
	left: -17px;
	top: -6px
}

.noUi-vertical {
	width: 18px
}

.noUi-vertical .noUi-handle {
	width: 28px;
	height: 34px;
	left: -6px;
	top: -17px
}

.noUi-background {
	background: #FAFAFA;
	box-shadow: inset 0 1px 1px #f0f0f0
}

.noUi-connect {
	background: #1f92bd;
	box-shadow: inset 0 0 3px rgba(51,51,51,.45);
	-webkit-transition: background 450ms;
	transition: background 450ms
}

.noUi-origin {
	border-radius: 2px
}

.noUi-target {
	border-radius: 4px;
	border: 1px solid #D3D3D3;
	box-shadow: inset 0 1px 1px #F0F0F0, 0 3px 6px -5px #BBB
}

.noUi-target.noUi-connect {
	box-shadow: inset 0 0 3px rgba(51,51,51,.45), 0 3px 6px -5px #BBB
}

.noUi-draggable {
	cursor: w-resize
}

.noUi-vertical .noUi-draggable {
	cursor: n-resize
}

.noUi-handle {
	border: 1px solid #D9D9D9;
	border-radius: 3px;
	background: #FFF;
	cursor: default;
	box-shadow: inset 0 0 1px #FFF, inset 0 1px 7px #EBEBEB, 0 3px 6px -3px #BBB
}

.noUi-active {
	box-shadow: inset 0 0 1px #FFF, inset 0 1px 7px #DDD, 0 3px 6px -3px #BBB
}

.noUi-handle:after, .noUi-handle:before {
	content: "";
	display: block;
	position: absolute;
	height: 14px;
	width: 1px;
	background: #E8E7E6;
	left: 14px;
	top: 6px
}

.noUi-handle:after {
	left: 17px
}

.noUi-vertical .noUi-handle:after, .noUi-vertical .noUi-handle:before {
	width: 14px;
	height: 1px;
	left: 6px;
	top: 14px
}

.noUi-vertical .noUi-handle:after {
	top: 17px
}

[disabled] .noUi-connect, [disabled].noUi-connect {
	background: #B8B8B8
}

[disabled] .noUi-handle, [disabled].noUi-origin {
	cursor: not-allowed
}

.noUi-pips, .noUi-pips * {
	-moz-box-sizing: border-box;
	box-sizing: border-box
}

.noUi-pips {
	position: absolute;
	color: #999;
	font-size: 10px
}

.noUi-value {
	position: absolute;
	text-align: center
}

.noUi-value-sub {
	color: #ccc;
	font-size: 10px
}

.noUi-marker {
	position: absolute;
	background: #CCC
}

.noUi-marker-large, .noUi-marker-sub {
	background: #AAA
}

.noUi-pips-horizontal {
	padding: 10px 0;
	height: 45px;
	top: 100%;
	left: 0;
	width: 100%
}

.noUi-value-horizontal {
	-webkit-transform: translate3d(-50%,50%,0);
	transform: translate3d(-50%,50%,0)
}

.noUi-marker-horizontal.noUi-marker {
	margin-left: -1px;
	width: 2px;
	height: 5px
}

.noUi-marker-horizontal.noUi-marker-sub {
	height: 10px
}

.noUi-marker-horizontal.noUi-marker-large {
	height: 12px
}

.noUi-pips-vertical {
	padding: 0 10px;
	height: 100%;
	top: 0;
	left: 100%
}

.noUi-value-vertical {
	-webkit-transform: translate3d(0,-50%,0);
	transform: translate3d(0,-50%,0);
	padding-left: 25px
}

.noUi-marker-vertical.noUi-marker {
	width: 5px;
	height: 2px;
	margin-top: -1px
}

.noUi-marker-vertical.noUi-marker-sub {
	width: 10px
}

.noUi-marker-vertical.noUi-marker-large {
	width: 15px
}

.noUi-tooltip {
	display: block;
	position: absolute;
	border: 1px solid #D9D9D9;
	border-radius: 3px;
	background: #fff;
	padding: 5px;
	text-align: center
}

.noUi-horizontal .noUi-handle-lower .noUi-tooltip {
	top: -32px
}

.noUi-horizontal .noUi-handle-upper .noUi-tooltip {
	bottom: -32px
}

.noUi-vertical .noUi-handle-lower .noUi-tooltip {
	left: 120%
}

.noUi-vertical .noUi-handle-upper .noUi-tooltip {
	right: 120%
}
