.page_simplecheckout h2 {
    position: relative;
    padding: 10px 25px;
    margin-bottom: 0;
    background: #e2e2e2;
    color: #000;
    font-size: 16px;
    text-transform: initial;
}

.page_simplecheckout h2:before {
    content: '' !important;
    position: absolute;
    z-index: 1;
    top: 50%;
    transform: translateY(-50%);
    left: 10px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: #e15a48;
}

.page_simplecheckout #container .alert {
    margin-left: 0 !important;
    margin-right: 0 !important;
    border-radius: 0;
}

.simple-content {
    margin: 0;
}

.page_simplecheckout .simple-content fieldset {
    text-align: left;
}

.page_simplecheckout .simple-content fieldset h3 {
    margin: 0;
}

.page_simplecheckout .simple-content .name {
    float: none !important;
}

.page_simplecheckout .simple-content .button {
    margin: 0 !important;
}

.page_simplecheckout .simple-content .buttons {
    margin: 0 0 15px 0;
    border: none;
}

.page_simplecheckout .simple-content .btn-primary {
    border: none !important;
    border-radius: 0 !important;
    background: #e2e2e2 !important;
    color: #000 !important;
    outline: none !important;
}

.page_simplecheckout .simple-content .btn-primary:hover,
.page_simplecheckout .simple-content .btn-primary:active,
.page_simplecheckout .simple-content .btn-primary:focus {
    border: none !important;
    outline: none !important;
}

.page_simplecheckout .simple-content .form-control {
    width: 100%;
}

.page_simplecheckout .simplecheckout {
    width: 100%;
}

.page_simplecheckout .simplecheckout:after {
    content: "";
    display: block;
    clear: both;
}

.page_simplecheckout .simplecheckout-three-column {
    float: left;
    margin-right: 2%;
    min-width: 200px;
    max-width: 500px;
    width: 32%;
}

.page_simplecheckout .simplecheckout-three-column + .page_simplecheckout .simplecheckout-three-column + .page_simplecheckout .simplecheckout-three-column {
    margin-right: 0;
    float: right;
}

.page_simplecheckout .simplecheckout-left-column {
    float: left;
    margin-right: 10px;
    min-width: 280px;
    max-width: 900px;
    width: 52%;
}

.page_simplecheckout .simplecheckout-right-column {
    float: right;
    min-width: 280px;
    max-width: 900px;
    width: 46%;
}

.page_simplecheckout .simplecheckout-block {
    clear: both;
    margin-bottom: 20px;
}

.page_simplecheckout .simplecheckout-block-content {
    padding: 10px;
}

.page_simplecheckout .simplecheckout-block-content:after {
    content: "";
    display: block;
    clear: both;
}

.page_simplecheckout .simpleregister {
    margin-bottom: 5px;
}

.page_simplecheckout .simpleregister-block-content {
    margin-bottom: 10px;
    padding: 10px;
}

.page_simplecheckout .simpleregister-block-content:after {
    content: "";
    display: block;
    clear: both;
}

.page_simplecheckout .simpleregister-block-content .simplecheckout-table-form-left {
    width: 50%;
}

.page_simplecheckout .simpleregister-button-block {
    overflow: auto;
    margin-bottom: 20px;
    padding: 6px;
    background: none repeat scroll 0 0 #FFFFFF;
    text-align: right;
}

.page_simplecheckout .simpleregister-button-right {
    float: right;
    text-align: right;
}

.page_simplecheckout .simpleregister-button-left {
    float: left;
    text-align: left;
}

.page_simplecheckout .simplecheckout-methods-table {
    width: 100%;
}

.page_simplecheckout .simplecheckout-methods-table td.code {
    width: 1px;
}

.page_simplecheckout .simplecheckout-methods-table td.title {
    padding: 5px;
}

.page_simplecheckout .simplecheckout-methods-table td.title label {
    display: block !important;
}

.page_simplecheckout .simplecheckout-methods-table td.quote {
    width: 15%;
    text-align: right;
}

.page_simplecheckout .simplecheckout-methods-description {
    padding: 5px;
    margin: 5px 0;
}

.page_simplecheckout .simplecheckout-required {
    color: #FF0000;
    font-weight: bold;
}

.page_simplecheckout .simplecheckout-button-block {
    clear: both;
    overflow: auto;
    margin-bottom: 20px;
    padding: 6px;

    background: none repeat scroll 0 0 #FFFFFF;
    text-align: right;
}

.page_simplecheckout .simplecheckout-button-right {
    float: right;
    text-align: right;
}

.page_simplecheckout .simplecheckout-button-left {
    float: left;
    text-align: left;
}

.page_simplecheckout .simplecheckout-button {
    display: inline-block;
    padding: 6px 12px 6px 12px;
    border-radius: 7px 7px 7px 7px;
    background: url('../image/button.png') top left repeat-x;
    -webkit-box-shadow: 0 2px 2px #f7f7f7;
    box-shadow: 0 2px 2px #f7f7f7;
    color: #FFFFFF;
    text-decoration: none;
    font-weight: bold;
    font-size: 12px;
    font-family: Arial, Helvetica, sans-serif;
    line-height: 12px;
    cursor: pointer;
    -khtml-border-radius: 7px 7px 7px 7px;
}

.page_simplecheckout .simplecheckout-button-left .button {
    margin: 0;
}

.page_simplecheckout .simplecheckout-button:hover {
    background: url('../image/button-active.png') repeat-x;
}

.page_simplecheckout #simplecheckout_customer .simplecheckout-block-content {
    text-align: center;
}

.page_simplecheckout .simplecheckout-login {
    display: inline-block;
}

.page_simplecheckout .simplecheckout-login-left {
    width: 50%;
    text-align: left;
}

.page_simplecheckout .simplecheckout-login-right {
    width: 50%;
    text-align: left;
}

.page_simplecheckout .simplecheckout-login-right .button {
    float: left;
    margin: 0;
}

.page_simplecheckout .simplecheckout-login-right input {
    max-width: 160px !important;
    text-align: left;
}

.page_simplecheckout #simplecheckout_cart {
    width: 100%;
    padding: 15px;
    border: 1px solid #f7f7f7;
}

.page_simplecheckout #simplecheckout_cart .form-control {
    display: inline-block !important;
    min-width: 50px;
}

.page_simplecheckout .simplecheckout-cart {
    margin: 0 !important;
    width: 100%;
    border-collapse: collapse;
    border-top: 1px solid #f7f7f7;
    border-right: 1px solid #f7f7f7;
    border-left: 1px solid #f7f7f7;
}

.page_simplecheckout .simplecheckout-cart-total {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    padding: 10px 12px;
    border-right: 1px solid #f7f7f7;
    border-bottom: 1px solid #f7f7f7;
    border-left: 1px solid #f7f7f7;
    text-align: right;
}

.page_simplecheckout .simplecheckout-cart-total:after {
    content: "";
    display: block;
    clear: both;
}

.page_simplecheckout .simplecheckout-cart-total .buttons {
    background: inherit !important;
    padding: 0 !important;
}

.page_simplecheckout .simplecheckout-cart-total input {
    margin-right: 0 !important;
    max-width: 100px;
}

.page_simplecheckout .simplecheckout-cart-total span.inputs {
    margin: 0 3px 0 0 !important;
    display: inline-block !important;
}

.page_simplecheckout .simplecheckout-cart-total span.inputs input[type=text] {
    margin: 0;
}

.page_simplecheckout .simplecheckout-cart-total-value {
    display: inline-block;
    margin: 0 3px;
    min-width: 60px;
}

.page_simplecheckout .simplecheckout-cart-total-remove {
    display: inline-block;
    width: 18px;
    text-align: center;
}

.page_simplecheckout .simplecheckout-cart-total-remove img {
    vertical-align: -2px;
    cursor: pointer;
}

.page_simplecheckout .simplecheckout-cart col.image {
    width: 5%;
}

.page_simplecheckout .simplecheckout-cart col.name {

}

.page_simplecheckout .simplecheckout-cart col.model {
    width: 10%;
}

.page_simplecheckout .simplecheckout-cart col.quantity {

}

.page_simplecheckout .simplecheckout-cart col.price {
    width: 10%;
}

.page_simplecheckout .simplecheckout-cart col.total {
    width: 10%;
}

.page_simplecheckout .simplecheckout-cart col.remove {
    width: 7px;
}

.page_simplecheckout .simplecheckout-cart th {
    padding: 8px;
    border-bottom: 1px solid #f7f7f7;
    background-color: #F8F8F8;
    color: #4D4D4D;
    font-weight: bold;
}

.page_simplecheckout .simplecheckout-cart th.image {
    width: 1px;
    text-align: center;
    display: table-cell;
    vertical-align: middle;
    position: static !important;
}

.page_simplecheckout .simplecheckout-cart th.name {
    text-align: left;
}

.page_simplecheckout .simplecheckout-cart .name {
    font-size: inherit !important;
}

.page_simplecheckout .simplecheckout-cart th.model {
    text-align: center;
}

.page_simplecheckout .simplecheckout-cart th.quantity {
    text-align: left;
}

.page_simplecheckout .simplecheckout-cart .price {
    text-align: right;
    display: table-cell !important;
    font: inherit !important;
}

.page_simplecheckout .simplecheckout-cart .total {
    text-align: right;
    display: table-cell !important;
    font: inherit !important;
}

.page_simplecheckout .page_simplecheckout .simplecheckout-cart .quantity {
    display: table-cell !important;
    float: none !important;
    position: static !important;
}

.page_simplecheckout .simplecheckout-cart th.remove {
    width: 7px;
    text-align: center;
}

.page_simplecheckout .simplecheckout-cart td {
    padding: 10px 12px;
    border-bottom: 1px solid #f7f7f7;
    border-right: 1px solid #f7f7f7;
    vertical-align: top;
}

.page_simplecheckout .page_simplecheckout .simplecheckout-cart td.image {
    text-align: center;
    display: table-cell;
    vertical-align: middle !important;
    position: static !important;
}

.page_simplecheckout .simplecheckout-cart td.name {
    text-align: left;
}

.page_simplecheckout .simplecheckout-cart td.name div.image {
    display: none;
}

.page_simplecheckout .simplecheckout-cart td.model {
    text-align: center;
}

.page_simplecheckout .simplecheckout-cart td.quantity {
    min-width: 70px;
    vertical-align: top;
    text-align: center;
    white-space: nowrap;
}

.page_simplecheckout .simplecheckout-cart td.quantity input {
    float: none !important;
    border: none;
}

.page_simplecheckout .simplecheckout-cart td.quantity .input-group {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin: auto !important;
}

.page_simplecheckout .simplecheckout-cart td.quantity .form-control[name^="quantity"] {
    display: inline-block !important;
    margin-left: 36px;
    min-width: 52px;
    max-width: 52px;
}

.page_simplecheckout .simplecheckout-cart td.quantity .quantity button {
    margin: 0 !important;
}

.page_simplecheckout .simplecheckout-cart td.quantity img {
    display: inline-block;
    border: 0;
    cursor: pointer;
}

.page_simplecheckout .simplecheckout-cart td.price {
    text-align: right;
    white-space: nowrap;
    display: table-cell !important;
    font: inherit !important;
}

.page_simplecheckout .simplecheckout-cart td.price div[style="text-decoration: line-through;"] {
    display: none;
}

.page_simplecheckout .simplecheckout-cart td.total {
    text-align: right;
    white-space: nowrap;
    display: table-cell !important;
}

.page_simplecheckout .simplecheckout-cart td.remove {
    padding-right: 3px;
    padding-left: 3px;
    width: 7px;
    vertical-align: top;
    text-align: center;
}

.page_simplecheckout .simplecheckout-cart td.remove img {
    max-width: 30px !important;
    cursor: pointer;
}

.page_simplecheckout .simplecheckout-cart td.remove .btn-danger {
    background: #e2e2e2;
    border: none !important;
}

.page_simplecheckout .simplecheckout-cart td.remove .btn-danger:hover,
.page_simplecheckout .simplecheckout-cart td.remove .btn-danger:active,
.page_simplecheckout .simplecheckout-cart td.remove .btn-danger:focus {
    border: none !important;
    outline: none !important;
}

.page_simplecheckout .simplecheckout-cart td.product-warning {
    color: #FF0000;
    font-weight: bold;
}

.page_simplecheckout .simplecheckout-cart td.right {
    vertical-align: middle;
    text-align: right;
}

.page_simplecheckout .simplecheckout-three-column .simplecheckout-cart col.image,
.page_simplecheckout .simplecheckout-three-column .simplecheckout-cart th.image,
.page_simplecheckout .simplecheckout-three-column .simplecheckout-cart td.image,
.page_simplecheckout .simplecheckout-three-column .simplecheckout-cart col.price,
.page_simplecheckout .simplecheckout-three-column .simplecheckout-cart th.price,
.page_simplecheckout .simplecheckout-three-column .simplecheckout-cart td.price,
.page_simplecheckout .simplecheckout-three-column .simplecheckout-cart col.model,
.page_simplecheckout .simplecheckout-three-column .simplecheckout-cart th.model,
.page_simplecheckout .simplecheckout-three-column .simplecheckout-cart td.model {
    display: none;
}

.page_simplecheckout .simplecheckout-three-column .simplecheckout-cart td.name .image {
    display: block;
}

.page_simplecheckout .simplecheckout-scroll {
    overflow-y: scroll;
    max-height: 300px;
}

.page_simplecheckout .simplecheckout-error-text {
    display: block;
    margin-top: 3px;
    color: #be7371;
    font-weight: 500;
}

.page_simplecheckout .simplecheckout-warning-text {
    margin: 5px 0;
    font-weight: bold;
}

.page_simplecheckout .simplecheckout-warning-block {
    clear: both;
    margin-bottom: 15px;
    padding: 10px 10px 10px 33px;
    border: 1px solid #F8ACAC;
    border-radius: 5px 5px 5px 5px;
    color: #555555;
    text-align: left;
}

.page_simplecheckout .simpleregister-warning-block {
    margin-bottom: 15px;
    padding: 10px 10px 10px 33px;
    border: 1px solid #F8ACAC;
    border-radius: 5px 5px 5px 5px;
    color: #555555;
    text-align: left;
}

.page_simplecheckout .simplecheckout-proceed-payment {
    display: none;
    margin-bottom: 10px;
    padding: 10px;
    text-align: center;
    font-weight: bold;
}

.page_simplecheckout #simple_payment_address_block {
    margin-top: 15px;
}

.page_simplecheckout .simplecheckout-customer-same-address {
    text-align: left;
    margin: 15px 0;
}

.page_simplecheckout #simplecheckout_login {
    margin: auto;
    text-align: center;
}

.page_simplecheckout #simplecheckout_payment_form {
    padding: 5px;
}

.page_simplecheckout#simplecheckout_payment_form .checkout-content {
    display: block;
}

.page_simplecheckout #simplecheckout_payment_form div.buttons {
    display: none;
}

.page_simplecheckout #simplecheckout_login .simplecheckout-block-content {
    padding: 5px !important;
}

.page_simplecheckout #simple_login_layer {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 99990;
    display: none;
    width: 100%;
    background-color: #000000;
}

.page_simplecheckout #simple_login {
    position: fixed;
    z-index: 99991;
    display: none;
    padding: 5px;
    width: 300px;
    height: 250px;
    border: 1px solid #FFD9FF;
    border-radius: 7px 7px 7px 7px;
    background: none repeat scroll 0 0 #FFFFFF;
    -khtml-border-radius: 7px 7px 7px 7px;
}

.page_simplecheckout #simple_login:after {
    content: "";
    display: block;
    clear: both;
}

.page_simplecheckout #simple_login_header {
    padding: 0;
    text-align: right;
}

.page_simplecheckout #simple_login_header img {
    margin: 0 0 0 auto;
}

.page_simplecheckout .you-will-be-registered {
    font-weight: bold;
}

.page_simplecheckout .simplecheckout label, .simpleregister label {
    display: inline-block !important;
    margin: 0;
}

.page_simplecheckout #simple_summary {
    display: none;
}

.page_simplecheckout #simplecheckout_step_menu {
    margin-bottom: 10px;
}

.page_simplecheckout .simple-step {
    display: inline-block;
    padding: 5px;
    border-radius: 5px 5px 5px 5px;
    background-color: #D9D9D9;
    color: #000000;
    font-size: 13px;
    -khtml-border-radius: 5px 5px 5px 5px;
    cursor: pointer;
    margin-bottom: 5px;
}

.page_simplecheckout .simple-step-current {
    display: inline-block;
    padding: 5px;
    border-radius: 5px 5px 5px 5px;
    background-color: #38B0E3;
    color: #FFFFFF;
    font-size: 13px;
    -khtml-border-radius: 5px 5px 5px 5px;
}

.page_simplecheckout .simple-step-delimiter {
    display: inline-block;
    vertical-align: middle;
    line-height: 13px;
    margin: 0 5px;
}

.page_simplecheckout .simple-step-completed {
    display: inline-block;
    padding: 5px;
    border-radius: 5px 5px 5px 5px;
    background-color: #38B0E3;
    color: #FFFFFF;
    font-size: 13px;
    -khtml-border-radius: 5px 5px 5px 5px;
}

.page_simplecheckout .simple-step-completed a {
    color: #FFFFFF !important;
    text-decoration: none !important;
    font-size: 13px !important;
}

.page_simplecheckout .simplecheckout-summary-info {
    margin-top: 15px !important;
}

.page_simplecheckout .simplecheckout-summary-info th.name {
    text-align: left;
}

.page_simplecheckout .simplecheckout-summary-info td {
    text-align: left;
}

.page_simplecheckout .simplecheckout-cart-buttons .buttons {
    border: none !important;
    padding: 0 !important;
}

.page_simplecheckout #simple_login .simplecheckout-block-content {
    border: none !important;
}

.page_simplecheckout #temp_flat_container {
    text-align: center;
    margin: 10px 0 20px;
}

.page_simplecheckout #simplecheckout_comment .checkout-heading {
    padding-top: 0;
    padding-left: 0;
    background: none !important;
}

.page_simplecheckout #simplecheckout_comment .simplecheckout-block-content {
    border: 0 !important;
    padding: 0 !important;
}

.page_simplecheckout #simplecheckout_comment textarea {
    width: 100% !important;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    min-height: 100px;
}

.page_simplecheckout .simplecheckout-table-form {
    width: 49%;
    margin-right: 1%;
    float: left;
    text-align: left;
}

.page_simplecheckout .simplecheckout-three-column .simplecheckout-table-form,
.page_simplecheckout .simplecheckout-left-column .simplecheckout-table-form,
.page_simplecheckout .simplecheckout-right-column .simplecheckout-table-form {
    width: 100%;
    margin-right: 0%;
    float: none;
}

.page_simplecheckout .simplecheckout-table-form-left {
    width: 50%;
    text-align: left;
    padding: 3px;
    vertical-align: top;
}

.page_simplecheckout .simplecheckout-table-form-right {
    width: 50%;
    text-align: left;
    padding: 3px;
}

.page_simplecheckout .simplecheckout-table-form-right input[type=text],
.page_simplecheckout .simplecheckout-table-form-right input[type=password],
.page_simplecheckout .simplecheckout-table-form-right input[type=email],
.page_simplecheckout .simplecheckout-table-form-right input[type=tel],
.page_simplecheckout .simplecheckout-table-form-right input[type=phone],
.page_simplecheckout .simplecheckout-table-form-right input[type=date],
.page_simplecheckout .simplecheckout-table-form-right input[type=time],
.page_simplecheckout .simplecheckout-table-form-right select,
.page_simplecheckout .simplecheckout-table-form-right textarea {
    width: 100%;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0 !important;
}

.page_simplecheckout .simplecheckout-table-form-right label {
    display: block !important;
}

.page_simplecheckout .simplecheckout-table-form-right label input {
    margin: 0 5px 0 0 !important;
}

.page_simplecheckout .checkout-heading:after {
    content: "";
    display: block;
    clear: both;
}

.page_simplecheckout .col-sm-12 .checkout-heading {
    background-color: #f5f5f5;
    border-color: #f7f7f7;
    color: #333;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    padding: 10px 15px;
}

.page_simplecheckout .checkout-heading span {
    display: inline-block !important;
    float: left;
    font-size: 16px;
}

.page_simplecheckout .checkout-heading-button {
    float: right !important;
    display: inline-block !important;
}

.page_simplecheckout .simplecheckout-select-address {
    text-align: left;
    margin-bottom: 10px;
}

.page_simplecheckout .simple-captcha-container {
    margin-top: 5px;
}

.page_simplecheckout .simplecheckout-tooltip {
    display: none;
}

.page_simplecheckout #easyTooltip {
    padding: 5px;
    border: 1px solid #ccc;
    background: #fde910;
    border-radius: 5px 5px 5px 5px;
    -webkit-box-shadow: 0 2px 2px #f7f7f7;
    box-shadow: 0 2px 2px #f7f7f7;
    opacity: 1 !important;
}

.page_simplecheckout #easyTooltip:before {
    content: '';
    position: absolute;
    left: 10px;
    top: -8px;
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 8px solid #fde910;
}

.page_simplecheckout .simplecheckout-cart i {
    cursor: pointer;
    color: #000;
}

.page_simplecheckout .simple-content .table-responsive {
    margin-bottom: 0 !important;
    border: none !important;
}

.page_simplecheckout .row-customer_register .radio {
    display: inline-block !important;
    margin-right: 10px;
}

.page_simplecheckout .simple-content .checkout-heading,
.simple-step {
    position: relative;
    background-color: #e2e2e2 !important;
    color: #000;
    border-color: rgba(221, 221, 221, 0);
    border-radius: 0;
    margin-bottom: 0;
    font-weight: normal;
    font-size: 16px;
}

#simplecheckout_customer .checkout-heading,
#simplecheckout_shipping_address .checkout-heading,
#simplecheckout_shipping .checkout-heading,
#simplecheckout_payment .checkout-heading {
    position: relative !important;
    padding-left: 25px;
}

#simplecheckout_customer .checkout-heading:before,
#simplecheckout_shipping_address .checkout-heading:before,
#simplecheckout_shipping .checkout-heading:before,
#simplecheckout_payment .checkout-heading:before {
    content: '' !important;
    position: absolute;
    z-index: 1;
    top: 50%;
    transform: translateY(-50%);
    left: 10px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: #e15a48;
}

.page_simplecheckout #simplecheckout_cart th {
    background: none;
    color: #000;
    border-color: rgba(221, 221, 221, 0);
    border-radius: 0;
    margin-bottom: 0;
    font-weight: normal;
    border-bottom: 1px solid #f7f7f7;
    border-right: 1px solid #f7f7f7;
}

.page_simplecheckout .simple-content .checkout-heading:before {
    display: inline-block;
    font-size: 16px !important;;
    font-family: FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    margin-right: 8px;
    vertical-align: bottom;
    float: left;
}

.page_simplecheckout .simple-content .checkout-heading a {
    color: #000;
}

.page_simplecheckout #simplecheckout_customer .checkout-heading:before {
    content: "\f007";
}

.page_simplecheckout #simplecheckout_shipping .checkout-heading:before {
    content: "\f0d1";
}

.page_simplecheckout #simplecheckout_payment .checkout-heading:before {
    content: "\f09d";
}

.page_simplecheckout #simplecheckout_shipping_address .checkout-heading:before,
.page_simplecheckout #simplecheckout_payment_address .checkout-heading:before {
    content: "\f0ac";
}

.page_simplecheckout .simple-content .form-control {
    border-radius: 0;
    background: #fafafa;
    font-size: 14px;
    font-weight: normal;
}

.page_simplecheckout .simplecheckout .simplecheckout-block-content .form-group .control-label {
    font-weight: normal;
    text-align: left;
}

.page_simplecheckout .simplecheckout .simplecheckout-block-content .form-group {
    width: 50%;
    float: left;
    padding: 0 10px;
    display: block;
    margin: 5px 0 10px;
}

.page_simplecheckout .simplecheckout .simplecheckout-block-content .form-group.row-customer_register {
    width: 100%;
    margin-bottom: 0;
}

.page_simplecheckout .simplecheckout .simplecheckout-block-content .form-group .col-sm-4, .simplecheckout .simplecheckout-block-content .form-group .col-sm-8 {
    display: block !important;
    float: none !important;
    width: 100%;
    padding: 0;
}

.page_simplecheckout .simplecheckout-block-content {
    border: 1px solid #eee;
    padding: 5px 10px;
}

.page_simplecheckout #simplecheckout_button_confirm {
    display: block !important;
    border: 1px solid #000;
    font-size: 14px;
    line-height: 46px;
    padding: 0 24px;
    min-height: 46px;
    border-radius: 4px;
}

.page_simplecheckout #simplecheckout_button_confirm:hover,
.page_simplecheckout #simplecheckout_button_confirm:focus,
.page_simplecheckout #simplecheckout_button_confirm:active {
    color: #000 !important;
}

.page_simplecheckout #button-confirm {
    display: none !important;
}

/* simple register */
.page_simpleregister .top-menu {
    margin: 0 0 8px 15px;
}

.page_simpleregister h2 {
    display: none;
}

.page_simpleregister .simpleregister-have-account {
    margin-top: 16px;
}

.page_simpleregister .form-horizontal .control-label {
    font-weight: normal;
}

.page_simpleregister .simpleregister-button-block {
    overflow: auto;
    margin: 0 0 15px 0;
    border: none;
    padding: 6px;
    background: none repeat scroll 0 0 #FFFFFF;
    text-align: right;
}

.page_simpleregister .simple-content .form-control {
    border-radius: 0;
    background: #fafafa;
    font-size: 14px;
    font-weight: normal;
}

.page_simpleregister #simpleregister_button_confirm {
    border: 1px solid #000;
    font-size: 14px;
    line-height: 28px;
    padding: 0 24px;
    min-height: 32px;
    border-radius: 4px;
}

.page_simpleregister #simpleregister_button_confirm:hover,
.page_simpleregister #simpleregister_button_confirm:focus,
.page_simpleregister #simpleregister_button_confirm:active {
    color: #000 !important;
}

.simpleregister .simplecheckout-rule-group {
    font-weight: normal;
    color: #be7371;
    margin-top: 6px;
}

#customer_register .radio label input,
#simplecheckout_payment .simplecheckout-block-content .radio label input,
#simplecheckout_shipping .simplecheckout-block-content .radio label input,
.page_simpleregister .row-register_newsletter .radio input[type=radio],
.page_simpleaddress .radio input[type=radio] {
    position: absolute;
    z-index: -1;
    opacity: 0;
}

#customer_register .radio label input:checked + span,
#simplecheckout_payment .simplecheckout-block-content .radio label input:checked + span,
#simplecheckout_shipping .simplecheckout-block-content .radio label input:checked + span,
.page_simpleregister .row-register_newsletter .radio input[type=radio]:checked + span,
.page_simpleaddress .radio input[type=radio]:checked + span {
    display: inline-flex;
    align-items: center;
    user-select: none;
}

#customer_register .radio label input + span::before,
#simplecheckout_payment .simplecheckout-block-content .radio label input + span:before,
#simplecheckout_shipping .simplecheckout-block-content .radio label input + span:before,
.page_simpleregister .row-register_newsletter .radio input[type=radio] + span:before,
.page_simpleaddress .radio input[type=radio] + span:before {
    content: '';
    position: relative;
    top: 2px;
    display: inline-block;
    width: 1em;
    height: 1em;
    flex-shrink: 0;
    flex-grow: 0;
    border: 1px solid #adb5bd;
    border-radius: 50%;
    margin-right: 0.5em;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 50% 50%;
}

#customer_register .radio label input:not(:checked) + span:before,
#simplecheckout_payment .radio label input:not(:checked) + span:before,
#simplecheckout_shipping .radio label input:not(:checked) + span:before,
.page_simpleregister .row-register_newsletter .radio input[type=radio]:not(:checked) + span:before,
.page_simpleaddress .radio input[type=radio]:not(:checked) + span:before {
    border-color: #252525;
}

#customer_register .radio label input:not(:disabled):active + span::before,
#simplecheckout_payment .radio label input:not(:disabled):active + span::before,
#simplecheckout_shipping .radio label input:not(:disabled):active + span::before,
.page_simpleregister .row-register_newsletter .radio input[type=radio]:not(:disabled):active + span::before,
.page_simpleaddress .radio input[type=radio]:not(:disabled):active + span::before {
    background-color: #ff7867;
    border-color: #ff7867;
}

#customer_register .radio label input:focus + span::before,
#simplecheckout_payment .radio label input:focus + span::before,
#simplecheckout_shipping .radio label input:focus + span::before,
.page_simpleregister .row-register_newsletter .radio input[type=radio]:focus + span::before,
.page_simpleaddress .radio input[type=radio]:focus + span::before {
    box-shadow: 0 0 0 0.2rem rgba(225, 90, 72, 0.27);
}

#customer_register .radio label input:focus:not(:checked) + span::before,
#simplecheckout_payment .radio label input:focus:not(:checked) + span::before,
#simplecheckout_shipping .radio label input:focus:not(:checked) + span::before,
.page_simpleregister .row-register_newsletter .radio input[type=radio]:focus:not(:checked) + span::before,
.page_simpleaddress .radio input[type=radio]:focus:not(:checked) + span::before {
    border-color: #ff7867;
}

#customer_register .radio label input:checked + span::before,
#simplecheckout_payment .radio label input:checked + span::before,
#simplecheckout_shipping .radio label input:checked + span::before,
.page_simpleregister .row-register_newsletter .radio input[type=radio]:checked + span::before,
.page_simpleaddress .radio input[type=radio]:checked + span::before {
    border-color: #ff7867;
    background-color: #ff8767;
    background-image: url(../images/radio.svg);
}

#customer_register .radio label input:disabled + span::before,
#simplecheckout_payment .radio label input:disabled + span::before,
#simplecheckout_shipping .radio label input:disabled + span::before,
.page_simpleregister .row-register_newsletter .radio input[type=radio]:disabled + span::before,
.page_simpleaddress .radio input[type=radio]:disabled + span::before {
    background-color: #e9ecef;
}

.simplecheckout-block-content .radio label {
    padding-left: 4px;
}

#simplecheckout_shipping .simplecheckout-block-content .radio:nth-child(1) {
    margin-bottom: 5px;
}

.page_simplecheckout .simplecheckout label[for="by_totalcity.by_totalcity_3"] > span:nth-child(3) {
    position: relative;
    top: -5px;
    display: inline-flex;
    padding-left: 1px;
    width: calc(100% - 30px);
}

.page_simplecheckout footer {
    margin: 0;
}

label[for="novaposhta.warehouse"] {
    width: 100%;
}

@media only screen and (max-width: 1200px) {
    .page_simplecheckout .simplecheckout label.col-sm-2 {
        padding-left: 5px;
        padding-right: 5px;
    }

    .page_simplecheckout .simplecheckout-block-content > fieldset:nth-of-type(2),
    .page_simplecheckout .simplecheckout-block-content > fieldset:nth-last-of-type(2) {
        width: 49%;
        margin-right: 1%;
        float: left;
        text-align: left;
    }
}

@media only screen and (max-width: 1024px) {
    .page_simplecheckout .simplecheckout .simplecheckout-block-content .form-group {
        width: 100%;
        float: none;
        padding: 0;
        margin: 0 0 10px 0;
    }
}

@media only screen and (max-width: 1000px) {
    .page_simplecheckout .simplecheckout-cart col.model,
    .page_simplecheckout .simplecheckout-cart th.model,
    .page_simplecheckout .simplecheckout-cart td.model {
        display: none;
    }
}

@media only screen and (max-width: 780px) {
    .page_simplecheckout .simplecheckout-button-left {
        display: none;
    }
}

@media only screen and (max-width: 769px) {
    #simplecheckout_shipping .simplecheckout-block-content .radio:nth-child(1) {
        margin-bottom: 10px;
    }

    .page_simplecheckout .simplecheckout label[for="by_totalcity.by_totalcity_3"]>span:nth-child(3) {
        position: relative;
        top: 0;
        display: inline-flex;
        padding-left: 1px;
        width: auto;
    }
}

@media only screen and (max-width: 768px) {
    .page_simplecheckout .simplecheckout-three-column {
        display: block;
        float: none;
        min-width: auto !important;
        max-width: 1900px !important;
        width: 100%;
    }

    .page_simplecheckout .simplecheckout-left-column {
        display: block;
        float: none;
        min-width: auto !important;
        max-width: 1900px !important;
        width: 100% !important;
    }

    .page_simplecheckout .simplecheckout-right-column {
        display: block;
        float: none;
        min-width: auto !important;
        max-width: 1900px !important;
        width: 100% !important;
    }

    .page_simplecheckout .simplecheckout label.col-sm-2 {
        padding-left: 15px;
        padding-right: 15px;
    }

    .page_simplecheckout .simplecheckout-table-form {
        width: 100%;
        margin-right: 0%;
        float: none;
    }
}

@media only screen and (max-width: 640px) {
    .page_simplecheckout .simplecheckout-cart col.image,
    .page_simplecheckout .simplecheckout-cart th.image,
    .page_simplecheckout .simplecheckout-cart td.image {
        display: none;
    }

    .page_simplecheckout .simplecheckout-cart col.model,
    .page_simplecheckout .simplecheckout-cart th.model,
    .page_simplecheckout .simplecheckout-cart td.model {
        display: none;
    }

    .page_simplecheckout .simplecheckout-cart col.price,
    .page_simplecheckout .simplecheckout-cart th.price,
    .page_simplecheckout .simplecheckout-cart td.price {
        display: none;
    }

    .page_simplecheckout .simplecheckout-cart td.name div.image {
        display: block;
    }

    .page_simplecheckout .simplecheckout-cart td.name div.options {
        display: none;
    }
}

@media only screen and (max-width: 600px) {
    .page_simplecheckout .simpleregister-block-content .simplecheckout-customer {
        width: 100%;
    }

    .page_simplecheckout #total_shipping span:first-child {
        max-width: 300px;
        display: inline-block;
    }

    .page_simplecheckout td.quantity button[data-onclick*="Quantity"] {
        display: none;
    }

    .page_simplecheckout #simplecheckout_customer .checkout-heading-button a {
        display: none;
    }

    .page_simplecheckout .simplecheckout-warning-block {
        padding: 10px;
        font-size: 11px;
    }

    .page_simplecheckout .page_checkout.page_simplecheckout .top-menu {
        margin: 0 0 8px 0;
    }

    .page_simplecheckout .page_simplecheckout article#content {
        padding: 0;
    }

    .page_simplecheckout .simplecheckout-cart-total {
        font-size: 13px;
    }

    #simplecheckout_shipping .simplecheckout-block-content .radio:nth-child(1) {
        margin-bottom: 5px;
    }

    .page_simplecheckout .simplecheckout label[for="by_totalcity.by_totalcity_3"]>span:nth-child(3) {
        position: relative;
        top: -5px;
        display: inline-flex;
        padding-left: 1px;
        width: calc(100% - 30px);
    }
}

@media only screen and (max-width: 420px) {
    .page_simplecheckout #total_shipping span:first-child {
        max-width: 200px;
        display: inline-block;
    }

    .page_simplecheckout .simplecheckout label[for="novaposhta.warehouse"] > span:nth-child(3) {
        display: inline-flex;
        padding-left: 1px;
        position: relative;
        top: -3px;
        width: calc(100% - 30px);
        margin: 6px 0 0;
    }
}

@media only screen and (min-width: 1200px) {
    .page_simplecheckout .simpleregister-block-content > fieldset:nth-of-type(2),
    .page_simplecheckout .simpleregister-block-content > fieldset:nth-last-of-type(2) {
        width: 49%;
        margin-right: 1%;
        float: left;
        text-align: left;
    }
}