@import url(../../../theme/storeset/style/bootstrap.css);
@import url(../../../theme/storeset/style/style.css);
@import url(../../../theme/storeset/style/owl.carousel.css);
@import url(../../../theme/storeset/style/addcart.css);
@import url(../../../theme/storeset/style/login.css);
@import url(../../../theme/storeset/style/themify-icons.css);
@import url(../../../theme/storeset/style/pe-icon-7-stroke.css);
@import url(../../../theme/storeset/style/font-awesome.4.6.0.css);
@import url(../../../javascript/popup_view/magnific-popup.css);
@import url(../../../theme/storeset/stylesheet/popup_view/stylesheet.css);
@import url(../../../theme/storeset/style/responsive.css);
@import url(../../../theme/storeset/stylesheet/popup_purchase/stylesheet.css);
@import url(../../../javascript/kjseries/kit-series.css);
@import url(../../../javascript/ocfilter/nouislider.min.css);
@import url(../../../theme/default/stylesheet/ocfilter/ocfilter.css);
@import url(../../../javascript/jquery/owl-carousel/owl.carousel.css);
@import url(../../../theme/storeset/stylesheet/megamenu.css);
@import url(../../../theme/storeset/style/magnify.css);
@import url(../../../theme/storeset/style/autosearch.css);
@import url(../../../javascript/popup_purchase/magnific-popup.css);
@import url(../../../theme/storeset/stylesheet/popup_call_phone/stylesheet.css);
@import url(../../../theme/storeset/stylesheet/oct_product_preorder/stylesheet.css);
@import url(../../../theme/storeset/style/storeset.css);
@import url(../../../javascript/mmenu-light/mmenu-light.css);
@import url(../style/iconmoon.css);
@import url(../../../theme/storeset/stylesheet/simple.css);
@import url(../../../javascript/select2-bootstrap.min.css);
@import url(../../../javascript/toastr.min.css);
@import url(../../../javascript/jquery/ui/jquery-ui.min.css);
@import url(../../../theme/default/stylesheet/shippingdata.css);
@font-face {
  font-family: "Roboto Light";
  src: url("../fonts/Roboto/RobotoLight/RobotoLight.ttf") format("truetype");
}

@font-face {
  font-family: "Made Evolve Sans Light";
  src: url("../fonts/madeevolvesans/Madeevolvesanslight.ttf") format("truetype");
}

.top-panel,
.search-box-top,
.navbar-fixed-bottom {
  background: #e2e2e2;
}

.top-panel {
  height: auto;
  padding: 0 0 4px;
  min-height: 25px;
  border-bottom: 2px solid #fff;
}

.top-panel .container .row {
  margin: 7px 0 3px;
}

.top-panel a {
  padding: 0 14px 0 0;
  line-height: initial;
  font-size: 14px;
}

.top-panel #oct-compare {
  display: none;
}

.top-panel-ul {
  height: auto;
  justify-content: center;
  margin: 0;
}

.top-panel-ul li a {
  float: none;
}

.top-panel-ul,
.top-panel-div {
  display: flex;
  flex-flow: row wrap;
}

.top-panel-div {
  justify-content: flex-end;
  padding-right: 0;
}

.top-panel-div #loginmodal {
  padding: 0 32px 0 0;
}

.top-panel-div a {
  padding: 0;
}

.top-panel-div .register .account-icon {
  display: inline-block;
  vertical-align: top;
  width: 13px;
  height: 15px;
  background: url("../images/account.svg") no-repeat center center;
  background-size: cover;
}

.top-panel-shedule {
  display: flex;
  align-items: flex-end;
  padding-left: 0;
}

.top-panel-shedule #shedule {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  padding: 0 0 0 0;
  font-size: 13px;
  line-height: 1.15;
  color: #000;
  text-align: center;
}

.top-panel-shedule #shedule .clockIcon {
  display: block;
  width: 16px;
  height: 16px;
  margin-top: -1px;
  margin-right: 4px;
  background: url("../images/clock.svg") no-repeat center center;
  background-size: cover;
}

.languages,
.currencies {
  display: flex;
  align-items: center;
}

.languages .btn,
.currencies .btn {
  padding: 0;
  background: none;
}

.languages .btn span,
.currencies .btn span {
  vertical-align: middle;
}

.languages .btn i,
.currencies .btn i {
  font-size: 8px;
  vertical-align: middle;
}

.languages .dropdown-menu,
.currencies .dropdown-menu {
  min-width: 40px;
  padding: 4px 0;
}

.languages .dropdown-menu a,
.currencies .dropdown-menu a {
  font-size: 14px;
}

.languages {
  padding-right: 15px;
}

.languages form {
  margin-bottom: 2px;
}

.languages .btn-group.open .dropdown-toggle {
  box-shadow: none;
}

.languages__btn {
  display: block;
  padding: 3px 20px;
  line-height: 1.42857143;
  white-space: nowrap;
  font-size: 14px;
  font-weight: 400;
  color: #000;
  background: #fff;
  border: none;
  outline: none;
}

.languages__btn:hover {
  background: #fff;
}

.currencies form {
  margin-bottom: 1px;
}

.currencies .btn-group.open .dropdown-toggle {
  box-shadow: none;
}

.currencies .btn-group.open .dropdown-menu .btn:hover {
  background: #fff;
}

.header-background {
  background: #e2e2e2;
}

header {
  padding: 8px 0 4px;
}

header.row {
  display: flex;
  align-items: center;
}

#contacts {
  padding-left: 15px;
  padding-right: 15px;
}

#contacts > #phone {
  position: relative;
  padding: 5px 0 3px 9px;
  font-size: 14px;
  line-height: initial;
  font-weight: 500;
  text-align: left;
}

#contacts > #phone > div {
  margin-bottom: 1px;
}

#contacts > #phone > div:last-child {
  margin-bottom: 0;
}

#contacts > #phone a {
  text-decoration: none;
  font-family: "Roboto Light", sans-serif;
  font-size: 14px;
  white-space: pre;
}

#contacts > #phone a:hover {
  color: #9C9C9C;
}

#contacts > #phone:before {
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  display: block;
  width: 3px;
  height: 100%;
  background: #fff;
  border-radius: 0;
}

.header_logo {
  margin: 0 auto;
  width: 100%;
  height: auto;
}

.header_logo img {
  max-width: 94.42%;
  margin: 0 auto;
}

.col-cart {
  margin: 33px 0 0;
}

#right-block {
  display: flex;
  justify-content: flex-end;
  padding: 0;
  margin: 0 0 13px 0;
}

#cart {
  float: none;
  padding: 0;
  margin: 0;
  background: transparent;
}

#cart .current-link {
  display: block;
  font-size: 0;
  transition: all .2s ease;
  text-decoration: none;
}

#cart .current-link:hover span {
  color: #9C9C9C;
}

#cart .current-link:hover .cart-icon {
  background: url("../images/cart_product_hover.svg") no-repeat center center;
  background-size: contain;
}

.cart-caption {
  display: inline-block;
  margin-right: 8px;
  vertical-align: middle;
}

.cart-caption .cart-text {
  display: inline-block;
  vertical-align: middle;
  margin-right: 7px;
  font-size: 18px;
  text-transform: capitalize;
  font-weight: 500;
}

.cart-caption .cart-icon {
  display: inline-block;
  vertical-align: middle;
  width: 20px;
  height: 20px;
  margin-right: 8px;
  background: url("../images/cart_product.svg") no-repeat center center;
  background-size: contain;
}

#cart-total {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  padding: 0 0 0 8px;
}

#cart-total:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 3px;
  border-radius: 0;
  background: #fff;
}

#cart-total span {
  display: block;
  margin: 0 0 4px;
  font-size: 14px;
  font-weight: 500;
  text-transform: lowercase;
}

#search {
  position: relative;
  right: auto;
  z-index: auto;
  width: 100%;
  margin: 0 0 26px;
  color: #000;
}

#search input {
  height: auto;
  padding: 4px 24px 5px 10px;
  margin: 0;
  background: #f1f1f1;
  border: 1px solid #f1f1f1;
  border-radius: 0;
  font-size: 14px;
}

#search input::placeholder {
  color: #000 !important;
}

.sb-search {
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  height: auto;
}

.sb-search-input {
  position: static;
  width: 100%;
}

.sb-search-submit {
  display: none;
}

.sb-search-icon {
  position: absolute;
  top: 50%;
  right: 7px;
  z-index: 10;
  transform: translateY(-50%);
  width: 12px;
  height: 16px;
  background: url("../images/loop.svg") no-repeat center center;
  color: #9C9C9C;
  cursor: pointer;
  transition: all .2s ease;
}

input#story::placeholder {
  color: #9C9C9C;
}

/* search */
#livesearch_search_results {
  position: absolute;
  z-index: 10;
  top: 28px;
  left: 0;
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  width: 100%;
  padding: 12px;
  box-shadow: none;
  background: #fff;
}

#livesearch_search_results::-webkit-scrollbar-track {
  box-shadow: none;
  border-radius: 0;
  background-color: #fff;
}

#livesearch_search_results::-webkit-scrollbar {
  width: 8px;
  background-color: #F5F5F5;
}

#livesearch_search_results::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #c1c1c1;
}

#livesearch_search_results img {
  max-width: calc(29% - 8px);
  margin-right: 8px !important;
  margin-bottom: 12px;
}

#livesearch_search_results li {
  width: 71%;
  margin-top: 6px;
  font-size: 13px;
}

#livesearch_search_results li a {
  padding: 0;
  text-decoration: none;
}

/* menu */
.menu-background {
  background: #fff;
}

#megamenu-menu {
  margin: 0;
  box-shadow: none;
}

#megamenu-menu .nav {
  box-shadow: none;
}

#megamenu-menu .nav:before, #megamenu-menu .nav:after {
  display: none;
}

#megamenu-menu .nav > li > a {
  position: relative;
  line-height: 17px !important;
  padding: 14px 2px 5px 18px !important;
  font-size: 18px;
  text-transform: inherit;
  font-weight: 500;
  letter-spacing: normal;
}

#megamenu-menu .nav > li > a:before {
  content: '';
  position: absolute;
  top: 19px;
  left: 0;
  background: #E15A48;
  border-radius: 50%;
  width: 8px;
  height: 8px;
  display: block;
}

#megamenu-menu .nav > li .megamenu-notoggle-a {
  padding: 0 !important;
}

#megamenu-menu .nav > li .megamenu-notoggle-a:before {
  display: none;
}

#megamenu-menu .nav > li:last-child > a {
  padding: 14px 2px 5px 18px !important;
}

#megamenu-menu .megamenu-type-category-full .megamenu-parent-title {
  font-weight: 500 !important;
}

#megamenu-menu .btn-navbar {
  padding-left: 0 !important;
  margin-left: 15px;
  outline: none;
  font-size: 24px;
  color: #000;
}

#megamenu-menu .btn-navbar:hover, #megamenu-menu .btn-navbar:focus, #megamenu-menu .btn-navbar:active {
  outline: none;
  color: #000;
}

#megamenu-menu .btn-navbar.disabled,
#megamenu-menu .btn-navbar[disabled] {
  outline: none;
  color: #000;
}

#megamenu-menu #category {
  color: #000;
  font-weight: 500;
}

.navbar-nav > li {
  min-height: 25px;
}

.navbar-nav > li:hover > a {
  color: #9C9C9C !important;
}

.navbar {
  min-height: 25px !important;
}

.navbar-collapse {
  padding-left: 0;
  padding-right: 0;
}

.cart-top-mobile .cart-cols {
  background: #F75D4A;
  color: #000;
}

a.dropdown-toggle.dropdown-img.megamenu-toggle-a.current-link:before, a.dropdown-toggle.dropdown-img.megamenu-toggle-a.current-link:after {
  background: #000;
}

/* mmenu */
.top-panel .sidebar-toggle {
  display: none;
}

.mm-ocd {
  top: 62px;
  z-index: 99999;
}

.mm-ocd li a:hover,
.mm-ocd li a:active,
.mm-ocd li a:focus {
  color: #F75D4A;
}

#header-mobile-menu.mm-spn {
  background: #000;
}

.mm-spn {
  font-size: 18px;
}

.mm-spn a {
  background: #000;
}

.mm-spn.mm-spn--navbar.mm-spn--main {
  background-color: #000;
  color: #fff;
  font-size: 18px;
}

.mm-spn.mm-spn--navbar:after {
  opacity: 1;
  color: #fff;
  text-align: left;
}

#header-mobile-menu-close-btn {
  position: absolute;
  top: -2px;
  right: -28px;
  width: 20px;
  height: 20px;
  z-index: 9999;
}

.mm-ocd:not(.mm-ocd--open) .hamburger {
  transition: all .2s ease;
  opacity: 0;
}

.hamburger {
  padding: 15px 15px;
  display: inline-block;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible;
}

.hamburger:hover {
  opacity: 0.7;
}

.hamburger.is-active:hover {
  opacity: 0.7;
}

.hamburger.is-active .hamburger-inner {
  background-color: #000;
}

.hamburger.is-active .hamburger-inner::before, .hamburger.is-active .hamburger-inner::after {
  background-color: #000;
}

.hamburger-box {
  width: 40px;
  height: 24px;
  display: inline-block;
  position: relative;
}

.hamburger-inner {
  display: block;
  margin-top: -2px;
  width: 34px;
  height: 4px;
  background-color: #000;
  border-radius: 0;
  position: absolute;
  transition-property: transform;
  transition-duration: 0.15s;
  transition-timing-function: ease;
  top: 2px;
  transform: translate3d(0, 10px, 0) rotate(45deg);
}

.hamburger-inner::before, .hamburger-inner::after {
  width: 34px;
  height: 4px;
  background-color: #000;
  border-radius: 0;
  position: absolute;
  transition-property: transform;
  transition-duration: 0.15s;
  transition-timing-function: ease;
}

.hamburger-inner::before, .hamburger-inner::after {
  content: "";
  display: block;
}

.hamburger-inner::before {
  opacity: 0;
  top: 10px;
  transition-property: transform, opacity;
  transition-timing-function: ease;
  transition-duration: 0.15s;
  transform: rotate(-45deg) translate3d(-5.71429px, -6px, 0);
}

.hamburger-inner::after {
  bottom: -10px;
  top: 20px;
  transform: translate3d(0, -20px, 0) rotate(-90deg);
}

.header-menu-text {
  display: none;
  float: left;
  margin-top: 18px;
  margin-left: -12px;
  font-size: 16px;
  color: #fff;
}

li.header-mobile-menu-contacts {
  padding: 13px 20px;
  font-size: 16px;
}

li.header-mobile-menu-contacts:before, li.header-mobile-menu-contacts:after {
  display: none;
}

li.header-mobile-menu-contacts h3 {
  margin: 0 0 14px;
  font-size: 16px;
}

li.header-mobile-menu-contacts a {
  padding: 2px 0;
  margin: 0;
}

li.header-mobile-menu-contacts a[href^="mailto"] {
  padding: 2px 0 0;
}

li.header-mobile-menu-contacts div {
  margin-bottom: 2px;
}

#header-mobile-menu {
  display: none;
}

.home #content .box-main {
  box-shadow: none;
}

.home #content .m-t-0 {
  margin-top: -34px;
}

.box-main {
  margin-top: 20px;
  margin-bottom: 12px;
  padding: 10px 0;
  box-shadow: none;
}

/* common */
.home #content > .box-main:first-child {
  display: none;
}

h1.resize {
  margin: 0;
  font-size: 0;
}

/* slide show */
#slideshow0 {
  margin: 0 0 17px;
}

.slideshow-box .owl-controls .owl-nav div i {
  font-size: 24px;
}

.slideshow-box .owl-dots {
  text-align: center;
  top: 90%;
  left: 50%;
  transform: translateX(-50%);
  position: absolute;
  cursor: pointer;
}

.slideshow-box .owl-dots .owl-dot span {
  background: #E15A48;
}

.slideshow-box .owl-dots .owl-dot.active span {
  background: none;
}

.slideshow-box .owl-controls .owl-nav div i {
  display: inline-block;
  vertical-align: middle;
  width: 10px;
  height: 21px;
  margin-right: 8px;
  background: url(../images/chevron-left-icon.svg) no-repeat center center;
  background-size: cover;
}

.slideshow-box .owl-controls .owl-nav div:nth-child(2) i {
  margin-left: 8px;
  margin-right: 0;
  background: url(../images/chevron-right-icon.svg) no-repeat center center;
  background-size: cover;
}

.slideshow-box .owl-controls .owl-nav .owl-prev {
  left: 20px;
}

.slideshow-box .owl-controls .owl-nav .owl-next {
  right: 20px;
}

.slideshow-box .owl-dots .owl-dot {
  margin: 0 4px;
}

.slideshow-box .owl-dots .owl-dot span {
  width: 8px;
  height: 8px;
  box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.3);
}

/* latest */
/* featured */
#cat-latest .chevron-left-icon,
[id^="cat-feautured-"] .chevron-left-icon,
#cat-bestseller .chevron-left-icon {
  display: inline-block;
  vertical-align: middle;
  width: 10px;
  height: 21px;
  margin-right: 8px;
  background: url("../images/chevron-left-icon.svg") no-repeat center center;
  background-size: cover;
}

#cat-latest .chevron-right-icon,
[id^="cat-feautured-"] .chevron-right-icon,
#cat-bestseller .chevron-right-icon {
  display: inline-block;
  vertical-align: middle;
  width: 10px;
  height: 21px;
  margin-right: 8px;
  background: url("../images/chevron-right-icon.svg") no-repeat center center;
  background-size: cover;
}

#cat-latest .item:hover a.out-of-stock-button,
[id^="cat-feautured-"] .item:hover a.out-of-stock-button,
#cat-bestseller .item:hover a.out-of-stock-button {
  visibility: visible;
}

#cat-latest .item:hover .name a,
[id^="cat-feautured-"] .item:hover .name a,
#cat-bestseller .item:hover .name a {
  color: #000;
}

#cat-latest .item:hover .image > a:before,
[id^="cat-feautured-"] .item:hover .image > a:before,
#cat-bestseller .item:hover .image > a:before {
  top: 0;
}

#cat-latest .item a.out-of-stock-button,
[id^="cat-feautured-"] .item a.out-of-stock-button,
#cat-bestseller .item a.out-of-stock-button {
  visibility: hidden;
}

#cat-latest .item a.out-of-stock-button,
[id^="cat-feautured-"] .item a.out-of-stock-button,
#cat-bestseller .item a.out-of-stock-button {
  margin: 19px auto 26px;
  padding: 8px 12px 9px;
  color: #000;
  border-radius: 5px;
}

#cat-latest .item .name:hover a,
[id^="cat-feautured-"] .item .name:hover a,
#cat-bestseller .item .name:hover a {
  color: #9C9C9C;
}

#cat-latest .item .image > a:before,
[id^="cat-feautured-"] .item .image > a:before,
#cat-bestseller .item .image > a:before {
  content: "";
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  z-index: 1;
  height: 100%;
  background: linear-gradient(0deg, #fff, rgba(255, 255, 255, 0));
}

#cat-latest .item .cart,
[id^="cat-feautured-"] .item .cart,
#cat-bestseller .item .cart {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 8px;
  width: 160px;
}

#cat-latest .item .cart .button-cart,
[id^="cat-feautured-"] .item .cart .button-cart,
#cat-bestseller .item .cart .button-cart {
  width: 86%;
  margin: 0 auto 8px;
  border-radius: 5px;
  text-transform: uppercase;
}

#cat-latest .item .cart .button-cart i,
[id^="cat-feautured-"] .item .cart .button-cart i,
#cat-bestseller .item .cart .button-cart i {
  margin-right: 3px;
}

#cat-latest .item .cart .button-cart:hover,
[id^="cat-feautured-"] .item .cart .button-cart:hover,
#cat-bestseller .item .cart .button-cart:hover {
  text-decoration: none;
}

#cat-latest .item .cart .buyByOneClick,
[id^="cat-feautured-"] .item .cart .buyByOneClick,
#cat-bestseller .item .cart .buyByOneClick {
  width: 100%;
  max-width: 138px;
  padding: 6px 12px;
  font-size: 15px;
  border-radius: 5px;
  border: 1px solid #c7c7c7;
}

#cat-latest .item .cart .buyByOneClick:hover,
[id^="cat-feautured-"] .item .cart .buyByOneClick:hover,
#cat-bestseller .item .cart .buyByOneClick:hover {
  color: #000;
  background: #e2e2e2;
  border-color: #e2e2e2;
  text-decoration: none;
}

#cat-latest .item .popup_view_button,
[id^="cat-feautured-"] .item .popup_view_button,
#cat-bestseller .item .popup_view_button {
  top: 32%;
  background: #f1f1f1;
  padding: 8px 2px;
  border-radius: 5px;
  border: none;
  text-transform: initial;
  font-size: 14px;
  color: #000 !important;
}

#cat-latest .item .popup_view_button:hover,
[id^="cat-feautured-"] .item .popup_view_button:hover,
#cat-bestseller .item .popup_view_button:hover {
  background: #F75D4A;
  color: #000 !important;
  text-decoration: none;
}

#cat-latest .item .price,
[id^="cat-feautured-"] .item .price,
#cat-bestseller .item .price {
  font-size: 14px;
  padding: 4px 12px;
  display: inline-block;
  border-radius: 5px;
}

#cat-latest .item .price-old,
[id^="cat-feautured-"] .item .price-old,
#cat-bestseller .item .price-old {
  padding: 0;
  display: inline-block;
  border-radius: 5px;
  color: #999;
}

#cat-latest .item .price-new,
[id^="cat-feautured-"] .item .price-new,
#cat-bestseller .item .price-new {
  padding: 0 0 0 12px;
  display: inline-block;
  border-radius: 5px;
  color: #f56b6b !important;
}

#cat-latest .item .rating,
[id^="cat-feautured-"] .item .rating,
#cat-bestseller .item .rating {
  position: absolute;
  bottom: 168px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 100;
}

.category-slide .owl-next,
.category-slide .owl-prev {
  color: #000;
  border: none;
  background: transparent;
  font-weight: 500;
}

.category-slide .owl-next:hover,
.category-slide .owl-prev:hover {
  color: #9C9C9C;
}

.news-set {
  margin: 3em 0 2em;
  position: relative;
  border-top: none;
}

.news-set span {
  font-size: 18px;
  font-weight: initial;
  font-family: "Made Evolve Sans Light";
}

.news-set:before {
  position: absolute;
  content: '';
  top: 2.4em;
  left: 0;
  width: 100%;
  height: 2px;
  background: #e0e0e0;
}

.news-set:after {
  position: absolute;
  content: '';
  bottom: 2.4em;
  left: 0;
  width: 100%;
  height: 2px;
  background: #e0e0e0;
}

.category-slide {
  max-height: initial;
}

.category-slide .owl-carousel .owl-stage-outer {
  max-height: initial;
}

.category-slide .owl-theme .owl-controls {
  top: -57px;
}

.category-slide .item .name {
  height: 58px;
}

.category-slide .item .cart .button-cart {
  color: #000;
}

.category-slide .item .cart:hover {
  color: #9C9C9C !important;
}

.category-slide .item .cart-icon {
  display: inline-block;
  vertical-align: middle;
  width: 16px;
  height: 14px;
  margin-right: 4px;
  margin-top: -3px;
  background: url(../images/cart_product.svg) no-repeat center center;
  background-size: cover;
  transition: all .2s ease;
}

a.out-of-stock-button {
  margin: 20px auto 10px;
  color: #fff;
  background: #E15A48;
}

.home .about {
  display: block;
}

.home .shopMap iframe {
  display: block;
}

.home .footer-top {
  padding: 0 0 30px;
}

.page_category .row + .content {
  margin: 0 -15px;
}

.page_category article#content {
  padding: 0 15px;
}

.top-menu {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  float: none;
  width: 100%;
  border-bottom: none;
  margin: 0;
}

.top-menu .category-header {
  position: relative;
  width: 100%;
  padding-left: 13px;
  text-transform: initial;
  font-size: 18px;
}

.top-menu .category-header::before {
  content: '';
  display: block;
  width: 8px;
  height: 8px;
  line-height: 33px;
  background: #E15A48;
  border-radius: 5px;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.top-menu > div {
  float: none;
}

.top-menu .display {
  width: 50%;
}

.top-menu .sort {
  width: 35%;
  background: url(../images/sort-arrow-down.png) 78% no-repeat;
}

.top-menu .sort select {
  border-bottom: 1px solid #E15A48;
  margin-right: 0;
}

.top-menu .show-items {
  width: 15%;
  background: url(../images/sort-arrow-down.png) 94% no-repeat;
}

.top-menu .show-items select {
  width: 100%;
  max-width: 53px;
  border-bottom: 1px solid #E15A48;
}

.category-list {
  display: flex;
  flex-flow: row wrap;
  border-bottom: none;
  margin: 0 -15px;
}

.category-list > div[class*='col-'] {
  display: flex;
  margin-bottom: 30px;
}

.category-list > div[class*='col-'] .category-list-item {
  position: relative;
  width: 100%;
  margin-bottom: 0;
  border: 1px solid #E15A48;
  background: #E15A48;
}

.category-list > div[class*='col-'] .category-list-item a {
  display: block;
  height: 100%;
  padding: 16px;
  line-height: 1.15;
  text-align: left;
  text-decoration: none;
  background: #fff;
}

.category-list > div[class*='col-'] .category-list-item a span {
  font-size: 13px;
  min-height: auto;
  height: auto;
  width: 93%;
  overflow: initial;
}

.category-list > div[class*='col-'] .category-list-item a .category-list-item__text {
  position: relative;
  padding-left: 13px;
  margin-bottom: 12px;
  min-height: 26px;
}

.category-list > div[class*='col-'] .category-list-item a .category-list-item__text:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 4px;
  height: 100%;
  background: #E15A48;
}

.category-list > div[class*='col-'] .category-list-item a .category-list-item__img {
  width: 100%;
}

.category-list > div[class*='col-'] .category-list-item a .category-list-item__img img {
  display: block;
  width: 100%;
  height: auto;
}

.category-list > div[class*='col-'] .category-list-item a:after {
  position: absolute;
  top: 24px;
  right: 6px;
  transform: translateY(-50%);
  content: "\f105";
  display: block;
  width: 24px;
  height: 24px;
  font: normal normal normal 21px/1 FontAwesome;
  text-align: center;
}

.category-list > div[class*='col-'] .category-list-item a:hover {
  text-decoration: underline;
}

.sort,
.show-items {
  font-size: 13px;
}

#sstore-3-level ul ul li a.toggle-a:before, #sstore-3-level ul ul li a.toggle-a:after {
  background: #000 !important;
}

#sstore-3-level > ul > li > a {
  font-size: 15px;
}

.product-grid .product {
  border: none;
}

.product-grid .product:hover {
  box-shadow: none;
}

.product-grid .product:hover .image > a:first-child:before {
  top: 0;
}

.product-grid .product .image > a:first-child {
  display: block;
}

.product-grid .product .image > a:first-child:before {
  content: "";
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(0deg, #fff, rgba(255, 255, 255, 0));
  transition: all .3s ease;
}

.product-grid .product .image:hover .popup_view_button {
  top: 32%;
  background: #f1f1f1;
  padding: 8px 2px;
  border-radius: 5px;
  border: none;
  text-transform: initial;
  font-size: 14px;
  color: #000 !important;
}

.product-grid .product .image:hover .popup_view_button:hover {
  background: #F75D4A;
  color: #000 !important;
  text-decoration: none;
}

.product-grid .product .product-about {
  left: 0;
  bottom: -72px;
  z-index: 1;
  background: transparent;
}

.product-grid .product .name {
  height: 58px;
  padding: 12px 4px 0;
  margin-bottom: 0;
}

.product-grid .product .name a {
  font-size: 14px;
  line-height: 1.15;
  color: #000;
}

.product-grid .product .name a:hover {
  text-decoration: none;
  color: #9C9C9C;
}

.product-grid .product .price {
  padding-bottom: 2px;
  border: none;
}

.product-grid .product .product-buttons {
  height: auto;
  padding: 0 20px 0;
}

.product-grid .product .product-buttons .cart {
  float: none;
  width: 130px;
  height: 28px;
  line-height: 28px;
  margin: 0 auto 12px;
  font-size: 16px;
  text-transform: capitalize;
  border-radius: 5px;
}

.product-grid .product .product-buttons .cart .cart-icon {
  display: inline-block;
  vertical-align: middle;
  width: 16px;
  height: 14px;
  margin-right: 4px;
  margin-top: -2px;
  background: url(../images/cart_product.svg) no-repeat center center;
  background-size: cover;
  transition: all .2s ease;
}

.product-grid .product .product-buttons .cart:hover {
  color: #9C9C9C !important;
}

.product-grid .product .product-buttons .buyByOneClick {
  width: 100%;
  max-width: 130px;
  padding: 6px 20px;
  font-size: 14px;
  text-decoration: none;
  border-radius: 5px;
  border: 1px solid #c7c7c7;
}

.product-grid .product .product-buttons .buyByOneClick:hover {
  color: #E15A48;
}

.product-grid .product .sticker {
  top: 14px;
  background: #f56b6b;
}

.product-grid .product .price-new {
  color: #f56b6b;
  font-size: 20px;
}

.product-grid .rating {
  top: -14px;
}

.product-grid .price-old,
.swiper-slide .price-old {
  margin-right: 8px;
}

.category-info {
  padding: 0 15px;
}

.category-info p {
  line-height: 18px;
  color: #444444;
  font-size: 14px;
}

#popup-purchase-wrapper .product-description {
  overflow-y: initial !important;
  font-size: 12px;
}

.pagination > li > a:focus,
.pagination > li > a:hover,
.pagination > li > span:focus,
.pagination > li > span:hover {
  background: #E15A48;
}

.product-right {
  min-height: auto;
  background: none;
  padding-bottom: 0;
}

.product-info {
  border-top: 1px solid #f2f2f2;
}

.product-info .model-box {
  display: none;
}

.product-info #button-cart {
  margin: 10px 0 -4px;
  font-size: 22px;
  border-radius: 5px;
  text-transform: capitalize;
  opacity: 1;
}

.product-info #button-cart:hover {
  color: #9C9C9C !important;
}

.product-info #button-cart .cart-icon {
  display: inline-block;
  vertical-align: middle;
  width: 20px;
  height: 17px;
  margin-right: 0;
  margin-top: -2px;
  background: url(../images/cart_product.svg) no-repeat center center;
  background-size: cover;
  transition: all .2s ease;
}

.product-info .cart {
  margin: 14px 0 6px;
}

.product-info .cart .button {
  line-height: 46px;
  border-radius: 5px;
  font-size: 16px;
  opacity: 1;
}

.product-info .cart .button:hover {
  color: #9C9C9C !important;
}

.product-info .cart #oneclickbuy {
  background: #fff !important;
  color: #000 !important;
  border: 1px solid #fff;
  font-size: 18px;
  border-radius: 5px;
  text-transform: initial;
  border-radius: 5px;
  border: 1px solid #c7c7c7;
}

.product-info .cart #oneclickbuy:hover {
  background: #e2e2e2 !important;
  color: #000 !important;
  border: 1px solid #c7c7c7;
}

.product-info .btn-minus button,
.product-info .btn-plus button {
  display: block;
}

.product-info .btn-minus button span,
.product-info .btn-plus button span {
  display: none;
}

.product-info .btn-minus button {
  position: relative;
  background: none !important;
}

.product-info .btn-minus button:hover {
  background: none !important;
}

.product-info .btn-minus button:before {
  content: '';
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url(../images/minus-sign.svg) no-repeat 45.5% center;
  background-size: 50%;
  background-color: transparent !important;
}

.product-info .btn-minus button:after {
  content: '';
  position: absolute;
  z-index: 1;
  top: 48%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 70%;
  height: 70%;
  background: #E15A48;
  border-radius: 50%;
}

.product-info .btn-plus button {
  position: relative;
  background: none !important;
}

.product-info .btn-plus button:hover {
  background: none !important;
}

.product-info .btn-plus button:before {
  content: '';
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url(../images/plus-sign.svg) no-repeat 50% center;
  background-size: 50%;
  background-color: transparent !important;
}

.product-info .btn-plus button:after {
  content: '';
  position: absolute;
  z-index: 1;
  top: 48%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 70%;
  height: 70%;
  background: #E15A48;
  border-radius: 50%;
}

.product-info .number {
  border: none !important;
}

.product-info .description {
  color: #000;
}

.product-info .description span.marked {
  padding: 0;
  background: transparent;
}

.product-info input.plus-minus {
  background: transparent;
}

.product-info .price-new#update_special {
  color: #f56b6b;
  font-size: 30px;
}

.product-info .price-old {
  color: #999;
}

.productAttributes {
  font-size: 14px;
  margin-bottom: 12px;
}

.productAttributes .productAttributes__title {
  font-size: 16px;
  border-bottom: 1px solid #e2e2e2 !important;
}

.productAttributes .table {
  margin-bottom: 0;
}

.productAttributes .table > tbody > tr > td {
  padding: 6px;
}

.productAttributes tr {
  border-bottom: 1px solid #e2e2e2;
}

.productAttributes tr:last-child {
  border-bottom: 1px solid transparent;
}

#tab-specification .table-bordered > tbody > tr > td.table-attr-name {
  border-right: 1px solid #e2e2e2;
}

.product-info .nav-tabs {
  border: none !important;
  border-radius: 0 !important;
}

.product-info .nav-tabs > li.active {
  border-top: 1px solid #f2f2f2 !important;
  border-left: 1px solid #f2f2f2 !important;
  border-right: 1px solid #f2f2f2 !important;
  border-bottom: 1px solid transparent !important;
}

.product-info .nav-tabs > li {
  background: none !important;
  border: none !important;
  border-radius: 0 !important;
  border-color: #f2f2f2 !important;
  border-top: 1px solid #f2f2f2 !important;
  border-left: 1px solid #f2f2f2 !important;
  border-right: 1px solid #f2f2f2 !important;
}

.product-info .nav-tabs > li a {
  position: relative;
  padding: 10px 15px 10px 23px;
  border: none !important;
}

.product-info .nav-tabs > li a:before {
  content: '';
  display: block;
  width: 8px;
  height: 8px;
  line-height: 33px;
  background: #E15A48;
  border-radius: 5px;
  position: absolute;
  top: 50%;
  left: 8px;
  transform: translateY(-50%);
}

.product-info .nav-tabs > li:hover {
  border-top: 1px solid #f2f2f2 !important;
  border-left: 1px solid #f2f2f2 !important;
  border-right: 1px solid #f2f2f2 !important;
  border-bottom: 1px solid transparent !important;
}

.product-info .nav-tabs > li:hover a {
  background: #fff !important;
  border: none !important;
}

.tabs-box {
  border-top: 1px solid transparent;
}

#tab-description {
  color: #000;
}

.page_product .content {
  margin-top: 0;
}

.page_product .product-crumbs {
  margin-bottom: 0;
}

/* kit series */
#kjseries {
  padding: 0 15px;
  font-size: 14px;
  overflow-x: auto;
}

#kjseries h3 {
  display: none;
}

#kjseries thead {
  background: #fff;
  border-bottom: 1px solid #000;
}

#kjseries .table-hover > tbody > tr:hover {
  background: #fff !important;
}

#kjseries .table-bordered,
#kjseries .table-bordered > tbody > tr > td,
#kjseries .table-bordered > tbody > tr > th,
#kjseries .table-bordered > tfoot > tr > td,
#kjseries .table-bordered > tfoot > tr > th,
#kjseries .table-bordered > thead > tr > td,
#kjseries .table-bordered > thead > tr > th {
  border: none;
}

#kjseries .table-bordered > thead > tr > td {
  font-size: 16px;
}

#kjseries .table-bordered > thead > tr > td.image {
  background: url(../images/flacon.svg) no-repeat center 25%;
  background-size: 40px 33px;
}

#kjseries .kj-item {
  border-bottom: 1px solid #e2e2e2;
  background: #fff !important;
}

#kjseries .kj-item:last-child {
  border-bottom: 1px solid transparent;
}

#kjseries .kj-item .meta_h1 a {
  color: #0000cd;
}

#kjseries tbody .price_special {
  width: 145px;
  text-align: center;
}

#kjseries tbody .price_special strike {
  color: #999;
}

#kjseries tbody .price_special big b {
  color: #f56b6b;
  font-size: 16px;
}

#kjseries button.btn-primary {
  width: 120px;
  margin: 0 auto;
  color: #000 !important;
  border: none;
  outline: none;
  border-radius: 5px;
  background: #E15A48 !important;
}

#kjseries button.btn-primary .cart-icon {
  display: inline-block;
  vertical-align: middle;
  margin-right: 4px;
  margin-top: -2px;
  background: url(../images/cart_product.svg) no-repeat center center;
  width: 20px;
  height: 15px;
  background-size: contain;
  transition: all .2s ease;
}

#kjseries button.btn-primary:hover {
  background: #e2e2e2 !important;
  color: #000 !important;
}

#kjseries hr {
  display: none;
}

.page_product .alert-success {
  position: relative;
}

.page_product .alert-success .close {
  position: absolute;
  top: 50%;
  right: 24px;
  transform: translateY(-50%);
}

button.btn.btn-primary.btn-block.add-kjseries {
  border: none;
}

button.btn.btn-primary.btn-block.add-kjseries:hover {
  background: #000 !important;
}

td.input_qty div {
  display: flex;
}

.productAttributes td:nth-of-type(1) {
  width: 26%;
}

.productAttributes td:nth-of-type(2) {
  width: 79%;
  padding-left: 15px !important;
}

.manufacturer-list {
  display: flex;
  padding: 17px 0 32px;
}

.manufacturer-list .manufacturer-heading {
  width: 18%;
  text-align: center;
  font-size: 30px;
  color: #E15A48;
}

.manufacturer-list .manufacturer-content {
  display: flex;
  width: 82%;
}

.manufacturer-list .manufacturer-content ul {
  width: 25%;
  margin-left: 0;
}

.manufacturer-list .manufacturer-content ul li {
  padding-right: 15px;
  margin: 0 0 12px 0;
  text-align: left;
}

.manufacturer-list .manufacturer-content ul li a {
  display: flex;
  align-items: center;
}

.manufacturer-list .manufacturer-content ul li a .man-list-logo {
  display: none;
}

.manufacturer-alpha {
  display: none;
}

.page_manufacturer .top-menu {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  float: none;
  width: 100%;
  border-bottom: none;
  margin: 0 0 15px;
}

.page_manufacturer .top-menu .category-header {
  position: relative;
  width: 100%;
  padding-left: 16px;
}

.page_manufacturer .top-menu .category-header::before {
  content: '';
  display: block;
  width: 8px;
  height: 8px;
  line-height: 33px;
  background: #E15A48;
  border-radius: 5px;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.page_manufacturer .top-menu .display {
  width: 50%;
}

.page_manufacturer .top-menu > div {
  float: none;
}

.page_manufacturer .top-menu .display {
  width: 65%;
}

.page_manufacturer .top-menu .sort {
  width: 23.5%;
  background: url(../images/sort-arrow-down.png) 89% no-repeat;
}

.page_manufacturer .top-menu .sort select {
  max-width: 160px;
}

.page_manufacturer .top-menu .show-items {
  width: 11.5%;
  background: url(../images/sort-arrow-down.png) 94% no-repeat;
}

.page_manufacturer .img-thumbnail {
  display: none;
  margin-bottom: 16px;
}

.page_manufacturer .content a:hover {
  text-decoration: none;
  color: #E15A48;
}

.page_manufacturer .content p {
  line-height: 22px;
}

.page_manufacturer .content .col-sm-12 > p {
  line-height: 17px;
  font-size: 13px;
  font-weight: 100;
  color: #000;
}

.page_manufacturer .content .col-sm-12 > h2,
.page_manufacturer .content .col-sm-12 > h3 {
  font-weight: 600;
}

.page_manufacturer .content .col-sm-12 > ul {
  list-style-type: none;
  padding: 0 0 0 30px;
  color: #000;
}

.page_manufacturer .content .col-sm-12 > ul > li {
  list-style: outside;
  padding: 0;
}

.page_manufacturer .content .pagination + .row .col-sm-12:before {
  content: "";
  top: -20px;
  left: 15px;
  width: calc(100% - 30px);
  height: 2px;
  background: linear-gradient(90deg, #fff 0, #000 50%, #fff 100%) repeat repeat;
  position: absolute;
}

.page_oct_fastorder i.fa {
  color: #fff;
}

.oct-fastorder-payment input[type=button]#button-confirm {
  background: #F75D4A !important;
}

#button-go {
  padding: 12px 24px;
  background: #F75D4A !important;
  color: #000 !important;
  border: 1px solid #000;
}

#button-go:hover {
  background: #E15A48 !important;
}

.page_checkout .top-menu {
  margin: 0 0 8px 0;
}

.page_checkout .input-group-btn .order-prev,
.page_checkout .input-group-btn .order-next {
  border: none;
}

.page_checkout .input-group-btn .order-prev i,
.page_checkout .input-group-btn .order-next i {
  color: #fff !important;
}

.page_checkout .form-control.order-quantity {
  height: 32px;
  padding: 0 8px 0 15px;
  border: none;
  outline: none;
}

.page_checkout .remove-btn {
  border: none;
}

.page_checkout .remove-btn i {
  color: #fff !important;
}

.fastorder-panel-default .panel-heading,
.fastorder-panel-default .panel-heading h4 {
  color: #fff;
}

.oct-fastorder-payment.text-right a {
  color: #000 !important;
}

#button-go {
  color: #fff !important;
  border-radius: 0;
  border: none;
}

.shipping-method .radio label,
.payment-method .radio label {
  position: relative;
  line-height: 18px;
}

.shipping-method .radio label input,
.payment-method .radio label input {
  position: absolute;
  z-index: -1;
  visibility: hidden;
}

.shipping-method .radio label span,
.payment-method .radio label span {
  position: absolute;
  z-index: 1;
  top: 45%;
  transform: translateY(-50%);
  left: 0;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #252525;
}

.shipping-method .radio label input:checked + span,
.payment-method .radio label input:checked + span {
  background: #E15A48;
}

.page_checkout.page_success .button.current-link {
  background: #F75D4A !important;
  text-decoration: none;
}

.page_checkout.page_success .button.current-link:hover {
  background: #E15A48 !important;
  color: #000 !important;
}

.page_information article#content {
  margin: 0 -15px;
}

.info-page p {
  line-height: 20px;
  font-size: 14px;
}

.info-page.content {
  line-height: 20px;
  font-size: 14px;
}

.page_oct_blog h1 {
  position: relative;
  width: 100%;
  padding-left: 16px;
}

.page_oct_blog h1::before {
  content: '';
  display: block;
  width: 8px;
  height: 8px;
  line-height: 33px;
  background: #E15A48;
  border-radius: 5px;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.page_oct_blog .top-menu .display {
  width: 63%;
}

.page_oct_blog .top-menu .sort {
  width: 25.5%;
  background: url(../images/sort-arrow-down.png) 89% no-repeat;
}

.page_oct_blog .top-menu .sort select {
  max-width: 160px;
}

.page_oct_blog .top-menu .show-items {
  width: 11.5%;
  background: url(../images/sort-arrow-down.png) 94% no-repeat;
}

.page_oct_blog .buttons .pull-right a.button.current-link {
  display: none;
}

.page_oct_blog .image {
  margin: 0 auto;
}

.blog-layout.blog-list {
  margin-top: 12px;
}

.blog-thumb {
  padding: 8px 8px 0;
}

.returnDescription {
  text-align: left;
  margin-bottom: 32px;
}

.returnDescription h3 {
  margin-bottom: 0;
  font-size: 16px;
  text-align: center;
}

.returnTitle {
  margin-bottom: 12px;
  font-weight: bold;
}

#uptocall-mini {
  border: 10px solid rgba(220, 204, 154, 0.15) !important;
}

#uptocall-mini .uptocall-mini-phone {
  background-color: #F75D4A !important;
}

#uptocall-mini .uptocall-mini-phone:before {
  background-size: 60%;
  background-image: url(../images/callback.svg) !important;
}

#popup-call-phone-wrapper .popup-footer a {
  background: #F75D4A !important;
  color: #000 !important;
}

#popup-call-phone-wrapper .popup-footer a:hover {
  background: #E15A48 !important;
  color: #000;
  text-decoration: none;
}

.testbutton {
  padding: 8px 15px;
  background: #F75D4A;
  font-size: 15px;
  text-decoration: none;
  font-weight: 500;
  border-radius: 5px;
  font-family: "Made Evolve Sans Light", sans-serif;
  text-transform: initial;
}

#partcont:before {
  top: 6px;
  right: 6px;
  width: 14px;
  height: 14px;
  background: url(../images/close.svg) no-repeat center center;
}

.mcartproductname {
  color: #000;
}

#partcont .heading a {
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
}

#partcont .heading a .cart-caption {
  margin-right: 8px;
}

.mini-cart-info a {
  text-decoration: none;
  color: #000;
}

.mini-cart-info a:hover {
  color: #9C9C9C;
}

.payment-quantity .btn-minus button,
.payment-quantity .btn-plus button {
  background: #F75D4A !important;
  color: #000 !important;
}

.payment-quantity .btn-minus button span,
.payment-quantity .btn-plus button span {
  background-color: transparent !important;
}

#popup-view-wrapper #button-cart {
  height: 37px;
  line-height: 37px;
  margin-top: 18px;
  text-align: center;
  border-radius: 5px;
  font-size: 17px;
}

#popup-view-wrapper #button-cart:hover {
  color: #9C9C9C !important;
}

#popup-view-wrapper #button-cart .cart-icon {
  display: inline-block;
  vertical-align: middle;
  width: 15px;
  height: 15px;
  margin-right: 4px;
  margin-top: -2px;
  background: url(../images/cart_product.svg) no-repeat center center;
  background-size: cover;
  transition: all .2s ease;
}

#popup-view-wrapper .popup-view-btn[onclick^=get_popup_purchase] {
  height: 37px;
  line-height: 37px;
  margin-top: 12px !important;
  background: transparent !important;
  border: 1px solid #E15A48;
  border-radius: 5px;
  font-size: 15px;
}

#popup-view-wrapper .popup-view-btn[onclick^=get_popup_purchase]:hover {
  color: #9C9C9C !important;
}

#popup-purchase-wrapper .popup-footer {
  background: #fff !important;
}

#popup-purchase-wrapper a {
  text-decoration: none;
}

#popup-purchase-wrapper button:hover {
  color: #000 !important;
}

#popup-purchase-wrapper .radio.radio-totalcity input {
  margin-top: 4px;
}

#popup-purchase-wrapper .radio.radio-totalcity label {
  line-height: 1.2em;
}

#popup-product-preorder-wrapper .popup-footer a {
  background: #F75D4A !important;
  color: #000 !important;
  text-decoration: none;
}

#popup-product-preorder-wrapper .popup-footer a:hover {
  background: #e2e2e2 !important;
  color: #000 !important;
}

.page_sreview_reviews .btn.btn-primary.current-link {
  background: #E15A48 !important;
  color: #000 !important;
  border: transparent;
}

.page_sreview_reviews .btn.btn-primary.current-link:hover {
  background: #E15A48 !important;
  color: #fff !important;
  border: transparent;
}

.page_sreview_reviews .buttons .pull-right a.btn.btn-primary.current-link {
  display: none;
}

.page_sreview_reviews button#button-review {
  background: #F75D4A;
  color: #000;
}

.page_sreview_reviews button#button-review:hover {
  background: #E15A48;
  color: #fff;
}

.page_sreview_reviews .avg_rating {
  color: #000;
}

.page_sreview_reviews .avg_rating span {
  border-bottom: 1px dashed #000;
}

.page_sreview_reviews .store-rating .rating-count {
  color: #000;
}

.page_sreview_reviews .store-rating .rating-from {
  color: #9C9C9C;
}

.page_sreview_reviews .btn-success {
  border: 1px solid #E15A48;
}

.page_information .buttons.info-buttons .right a.button.current-link {
  display: none;
}

.shopMap iframe {
  display: none;
  width: 100%;
  height: 600px;
  border: none;
}

.about {
  display: none;
}

.footer-top {
  padding: 0;
}

.footer-top .about {
  padding: 0 30px;
}

.footer-top .about p {
  color: #000;
  line-height: 20px;
  font-size: 14px;
}

footer .footer-middle {
  background: #e2e2e2;
  background-size: cover;
  padding: 0;
  font-size: 16px;
}

footer .footer-middle h3 {
  display: flex;
  align-items: center;
  padding-bottom: 12px;
  margin-bottom: 0;
  color: #000;
  text-transform: initial;
  line-height: 18px;
}

footer .footer-middle ul > li > a {
  color: #000 !important;
  font-size: 14px;
}

footer .footer-middle ul > li > a:visited {
  font-size: 14px;
}

footer .footer-middle ul > li:hover a {
  text-decoration: none;
  color: #9C9C9C !important;
}

footer .footer-middle .container .row:first-child {
  display: flex;
  padding: 17px 0 14px;
  margin: 0;
}

footer .footer-middle .container .row:first-child p {
  margin-bottom: -12px;
  font-size: 24px;
  line-height: 28px;
  color: #000;
}

footer .footer-middle .footer_logo {
  display: flex;
  justify-content: center;
}

footer .footer-middle-bottom {
  padding: 0;
  background: #fff;
}

footer .footer-middle-bottom ul > li {
  padding: 0;
}

footer .footer-middle-bottom .information-icon {
  display: inline-block;
  vertical-align: middle;
  width: 18px;
  height: 18px;
  margin-right: 9px;
  background: url("../images/information-icon.svg") no-repeat center center;
  background-size: cover;
}

footer .footer-middle-bottom .account-icon {
  display: inline-block;
  vertical-align: middle;
  width: 15px;
  height: 18px;
  margin-right: 8px;
  background: url("../images/account-icon.svg") no-repeat center center;
  background-size: cover;
}

footer .footer-middle-bottom .support-icon {
  display: inline-block;
  vertical-align: middle;
  width: 18px;
  height: 18px;
  margin-right: 8px;
  background: url("../images/support-icon.svg") no-repeat center center;
  background-size: cover;
}

footer .footer-middle-bottom .marker-icon {
  display: inline-block;
  vertical-align: middle;
  width: 18px;
  height: 18px;
  margin-right: 8px;
  background: url("../images/marker-icon.svg") no-repeat center center;
  background-size: cover;
}

footer .contacts h3 {
  text-transform: initial;
}

footer .contacts ul li {
  line-height: 1.5;
  color: #000 !important;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

footer .contacts ul li span {
  color: #000;
  font-size: 14px;
}

#powered {
  display: block;
  padding: 10px 0;
}

#powered .copyright {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
}

#powered .copyright .copyright__company,
#powered .copyright .copyright__creator {
  width: 50%;
}

#powered .copyright .copyright__company p,
#powered .copyright .copyright__company a,
#powered .copyright .copyright__creator p,
#powered .copyright .copyright__creator a {
  color: #fff;
  font-size: 14px;
}

#powered .copyright .copyright__company p img,
#powered .copyright .copyright__company a img,
#powered .copyright .copyright__creator p img,
#powered .copyright .copyright__creator a img {
  margin-bottom: 2px;
  vertical-align: bottom;
}

#powered .copyright .copyright__company a:hover,
#powered .copyright .copyright__creator a:hover {
  text-decoration: none;
  color: #9C9C9C !important;
}

#powered .copyright .copyright__company {
  text-align: left;
}

.footer-middle a[href$="/add-return/"] {
  display: none;
}

a.register.logged {
  background: #000;
}

ul.account-ul-last {
  display: flex !important;
  flex-flow: row wrap;
  justify-content: flex-start;
}

.page_special .top-menu .display {
  width: 65%;
}

.page_special .top-menu .sort {
  width: 23.5%;
  background: url(../images/sort-arrow-down.png) 89% no-repeat;
}

.page_special .top-menu .sort select {
  max-width: 160px;
}

.page_special .top-menu .show-items {
  width: 11.5%;
  background: url(../images/sort-arrow-down.png) 94% no-repeat;
}

.page_search .top-menu .display {
  width: 65%;
}

.page_search .top-menu .sort {
  width: 23.5%;
  background: url(../images/sort-arrow-down.png) 89% no-repeat;
}

.page_search .top-menu .sort select {
  max-width: 160px;
}

.page_search .top-menu .show-items {
  width: 11.5%;
  background: url(../images/sort-arrow-down.png) 94% no-repeat;
}

.news-full-desc .img-thumbnail {
  display: none;
}

/* category */
.top-menu-filter {
  display: none;
}

/* ocfilter */
#ocfilter {
  border: none;
  border-radius: 0;
}

#ocfilter .panel-heading {
  display: none;
}

#ocfilter .list-group-item:not(.ocfilter-option-custom).clicked:after {
  background: #E15A48;
}

#ocfilter .list-group-item {
  position: relative;
  z-index: 1;
  max-height: 31px;
  padding: 0;
  margin: 1px 0 0;
  border: none;
  cursor: pointer;
  transition: all .25s ease;
}

#ocfilter .list-group-item:not(.ocfilter-option-custom):before {
  content: '';
  position: absolute;
  z-index: -1;
  top: 6px;
  right: 10px;
  width: 16px;
  height: 8px;
  background: url(../image/arrow_down_white.svg) no-repeat center center;
  background-size: cover;
  margin-right: 0;
  margin-top: 7px;
  transform: rotate(-90deg);
  transition: all .25s ease;
}

#ocfilter .list-group-item:not(.ocfilter-option-custom):after {
  content: '';
  position: absolute;
  z-index: -2;
  top: 0;
  left: 0;
  width: 100%;
  height: 31px;
  background: #e2e2e2;
}

#ocfilter .list-group-item.selected-options:after {
  display: none;
}

#ocfilter .list-group-item.clicked {
  max-height: 195px;
  transition: all .25s ease;
}

#ocfilter .list-group-item.clicked .ocf-option-values {
  visibility: visible;
}

#ocfilter .list-group-item.clicked:before {
  transform: rotate(0);
  transition: all .25s ease;
}

#ocfilter .list-group .list-group-item:last-child {
  overflow: hidden !important;
}

#ocfilter .ocfilter-option .ocf-option-name {
  padding: 10px 13px 5px 6px;
  margin: 0;
  background: none;
  font-family: "Made Evolve Sans Light",sans-serif;
  font-size: 14px;
  font-weight: initial;
}

#ocfilter .ocfilter-option:not(.ocfilter-option-custom) .ocf-option-values {
  overflow-y: scroll;
  height: auto;
  max-height: 155px;
  visibility: hidden;
  background: #e2e2e2;
}

#ocfilter .ocfilter-option.clicked .ocf-option-values {
  visibility: visible;
}

#ocfilter .ocfilter-option .ocf-option-name label {
  position: relative;
  font-size: 14px;
  font-family: "Made Evolve Sans Light", sans-serif;
}

#ocfilter .ocfilter-option > .ocf-option-values > label {
  position: relative;
  display: flex;
  padding-left: 12px;
  margin: 6px 0 9px;
  font-size: 14px;
}

#ocfilter .ocfilter-option > .ocf-option-values > label:before {
  content: '';
  position: absolute;
  top: 50%;
  left: 6px;
  transform: translateY(-50%);
  display: block;
  width: 14px;
  height: 14px;
  background: #fff;
  background-size: cover;
  border-radius: 50%;
}

#ocfilter .ocfilter-option > .ocf-option-values > label.ocf-selected:after {
  content: '';
  position: absolute;
  top: 50%;
  left: 9px;
  background: #E15A48;
  transform: translateY(-50%);
  display: block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-size: cover;
}

#ocfilter .ocfilter-option > .ocf-option-values > label > input {
  height: 0;
}

#ocfilter .ocfilter-option > .ocf-option-values > label small {
  font-size: 12px;
}

#ocfilter .ocfilter-option label.ocf-selected {
  color: #8D362A;
}

#ocfilter .list-group .ocfilter-option:not(.ocfilter-option-custom) .ocf-option-values::-webkit-scrollbar {
  width: 6px;
}

#ocfilter .list-group .ocfilter-option:not(.ocfilter-option-custom) .ocf-option-values::-webkit-scrollbar-track {
  border-radius: 5px;
}

#ocfilter .list-group .ocfilter-option:not(.ocfilter-option-custom) .ocf-option-values::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background: #9da0a4;
  box-shadow: inset 0 0 3px #9da0a4;
}

#ocfilter .selected-options {
  color: #000;
  max-height: initial;
  margin: 1px 0 10px;
}

#ocfilter .selected-options .ocfilter-option {
  padding: 4px 0;
  margin-bottom: 0;
  background: #fff;
  font-size: 14px;
}

#ocfilter .selected-options .ocfilter-option:last-of-type {
  padding: 0 0 4px;
}

#ocfilter .selected-options .ocfilter-option .btn-xs {
  font-size: 10px;
  margin-bottom: 5px;
  background: #8D362A;
}

#ocfilter .selected-options:before {
  display: none;
}

#ocfilter .selected-options .btn-danger-custom {
  margin-top: -2px;
}

#ocfilter .list-group-item:not(:nth-child(1)) {
  border-top: 1px solid #fff;
}

.ocf-offcanvas.ocf-offcanvas-hide {
  visibility: hidden;
}

/* ocfilter-option-custom */
#ocfilter .list-group-item.ocfilter-option-custom {
  max-height: 31px;
  position: relative;
  z-index: 1;
  border: none;
  cursor: pointer;
  transition: max-height .25s ease;
}

#ocfilter .list-group-item.ocfilter-option-custom:after {
  content: '';
  position: absolute;
  bottom: -12px;
  left: 0;
  background: #e2e2e2;
  width: 100%;
  height: 30px;
  z-index: -5;
}

#ocfilter .list-group-item.ocfilter-option-custom.clicked {
  max-height: 98px;
}

#ocfilter .list-group-item.ocfilter-option-custom .popover-custom {
  display: block;
  position: inherit;
  width: 100%;
  padding: 0;
  box-shadow: none;
  border: none;
  z-index: -2;
  background: #e2e2e2;
}

#ocfilter .list-group-item.ocfilter-option-custom .popover-custom input {
  height: 26px;
  width: 100%;
  min-width: 48px;
  max-width: 57px;
  padding: 0 4px;
  margin: 0 6px;
  border: 1px solid #e2e2e2;
  font-size: 13px;
}

#ocfilter .list-group-item.ocfilter-option-custom .popover-custom .popover-content {
  padding: 9px 14px 9px 0;
}

#ocfilter .list-group-item.ocfilter-option-custom .popover-custom .popover-title {
  padding: 7px 13px 5px 6px;
  color: #000;
  font-size: 15px;
  font-family: "Made Evolve Sans Light", sans-serif;
  border: none;
  border-radius: 0;
  background: none;
  padding-bottom: 0;
  margin: 0;
  text-transform: initial;
}

#ocfilter .list-group-item.ocfilter-option-custom .popover-title:before {
  content: '';
  position: absolute;
  z-index: -1;
  top: 6px;
  right: 10px;
  width: 16px;
  height: 8px;
  background: url(../image/arrow_down_white.svg) no-repeat center center;
  background-size: cover;
  margin-right: 0;
  margin-top: 7px;
  transform: rotate(-90deg);
  transition: all .25s ease;
}

#ocfilter .list-group-item.ocfilter-option-custom .popover-title:after {
  content: '';
  position: absolute;
  z-index: -2;
  top: 0;
  left: 0;
  width: 100%;
  height: 31px;
  background: #e2e2e2;
}

#ocfilter .list-group-item.ocfilter-option-custom.clicked .popover-title:before {
  transform: rotate(0);
  transition: all .25s ease;
}

#ocfilter .list-group-item.ocfilter-option-custom.clicked .popover-title:after {
  background: #E15A48;
}

#ocfilter .list-group-item.ocfilter-option-custom .popover-content .form-inline {
  padding-left: 7px;
}

#ocfilter .list-group-item.ocfilter-option-custom .popover-content .form-inline .form-group {
  font-size: 14px;
  line-height: 18px;
  font-family: "Made Evolve Sans Light", sans-serif;
}

#ocfilter .list-group-item.ocfilter-option-custom .popover .form-inline .form-control {
  width: 100px;
  height: 26px;
  min-width: 48px;
  max-width: 57px;
  padding: 0 0 0 4px;
  margin: 0 6px;
  border: 1px solid #e2e2e2;
  font-family: "Made Evolve Sans Light", sans-serif;
  font-size: 13px;
}

#ocfilter .list-group-item.ocfilter-option-custom .ocf-option-name-custom {
  display: none;
  margin-bottom: 0;
}

#ocfilter .list-group-item.ocfilter-option-custom .ocf-option-values {
  margin: 0 -7px;
}

#ocfilter .list-group-item.ocfilter-option-custom .ocf-option-values .noUi-target {
  height: auto;
  margin: 0 15px 12px 15px;
  border-radius: 0;
  border: 2px solid #000;
  background: #000;
  box-shadow: none;
}

#ocfilter .list-group-item.ocfilter-option-custom .ocf-option-values .noUi-horizontal .noUi-handle {
  background: #E15A48;
  border-radius: 50%;
  border: none;
}

#ocfilter .list-group-item.ocfilter-option-custom .noUi-target {
  height: auto;
  margin: 0 15px 12px 15px;
}

#ocfilter .list-group-item.ocfilter-option-custom .noUi-horizontal .noUi-handle {
  left: -6px;
  width: 12px;
  height: 12px;
  box-shadow: none;
}

#ocfilter .list-group-item.ocfilter-option-custom .noUi-horizontal .noUi-handle:before,
#ocfilter .list-group-item.ocfilter-option-custom .noUi-horizontal .noUi-handle:after {
  display: none;
}

#ocfilter .list-group-item.ocfilter-option-custom .noUi-connect {
  background: none;
}

#ocfilter .list-group-item.ocfilter-option-custom .noUi-pips-horizontal {
  display: none;
}

/* selected */
.list-group-item.selected-options button {
  margin-bottom: 16px;
}

html, body {
  height: 100%;
}

body {
  display: flex;
  flex-direction: column;
  font-family: "Made Evolve Sans Light", sans-serif;
  line-height: 1.15;
  font-size: 14px;
}

body #oct-wrap {
  flex: 1 0 auto;
}

.oct-button {
  background: #E15A48;
}

.oct-button:hover {
  background: #000;
}

.content {
  margin-top: 0;
}

#container {
  margin-top: 0;
  border-top: none;
  box-shadow: none;
}

#container .breadcrumb {
  padding: 8px 24px 16px;
  line-height: 2.15;
}

.breadcrumb > li:first-child + li:before {
  padding: 0;
}

.breadcrumb > li + li:before {
  padding: 0 5px 0 0;
}

.content {
  margin-top: 0;
}

article#content {
  padding: 0;
}

#input-sort {
  max-width: 160px;
}

.hideMe {
  visibility: hidden;
}

#back-top span {
  background: url(../images/back-top.png) center 48% no-repeat;
  background-size: 116%;
  border-radius: 0;
}

.table-bordered tr:nth-child(2n) {
  background: #fff;
}

/* icons */
.cart-icon-white {
  display: inline-block;
  vertical-align: middle;
  width: 25px;
  height: 25px;
  background: url(../images/cart_product_white.svg) no-repeat center center;
  -webkit-background-size: cover;
  background-size: cover;
}

footer {
  flex: 0 0 auto;
  margin: 0 0 -60px;
}

.m-t-0 {
  margin-top: 0;
}

/*__________ TODO DESKTOP FIRST __________ */
@media screen and (max-width: 1200px) {
  /* header */
  #contacts > #phone {
    font-size: 14px;
  }
  #contacts > #phone:before {
    left: -4px;
  }
  #livesearch_search_results {
    top: 80px;
    min-width: 212px;
  }
  /* category */
  /* manufacturer */
  /* search */
  /* special */
  /* news */
  .top-menu .display {
    width: 40%;
  }
  .top-menu .sort {
    width: 40%;
    background: url(../images/sort-arrow-down.png) 86% no-repeat;
  }
  .top-menu .show-items {
    width: 20%;
    background: url(../images/sort-arrow-down.png) 88% 62% no-repeat;
  }
  .page_category #container .content {
    margin-left: -15px;
    margin-right: -15px;
  }
  .page_manufacturer .top-menu .display,
  .page_search .top-menu .display,
  .page_special .top-menu .display,
  .page_oct_blog .top-menu .display {
    width: 55%;
  }
  .page_manufacturer .top-menu .sort,
  .page_search .top-menu .sort,
  .page_special .top-menu .sort,
  .page_oct_blog .top-menu .sort {
    width: 28%;
  }
  .page_manufacturer .top-menu .show-items,
  .page_search .top-menu .show-items,
  .page_special .top-menu .show-items,
  .page_oct_blog .top-menu .show-items {
    width: 14%;
    background: url(../images/sort-arrow-down.png) 93% 62% no-repeat;
  }
  /* product */
  .product-info .btn-minus button:before {
    background: url(../images/minus-sign.svg) no-repeat 55.5% center;
    background-size: 50%;
  }
  .product-info .btn-plus button:before {
    background: url(../images/plus-sign.svg) no-repeat 52% center;
    background-size: 50%;
  }
  /* information */
  .page_information article#content {
    margin: 0;
  }
  /* ocfilter */
  .ocf-offcanvas .ocfilter-mobile-handle {
    position: absolute;
    left: 100%;
    top: 73px;
  }
  .ocf-offcanvas .ocfilter-mobile-handle .btn {
    padding: 7px 14px;
    background: #F75D4A !important;
    border: none;
    border-radius: 0;
    color: #000 !important;
    font-size: 20px;
  }
  .ocf-offcanvas .ocfilter-mobile-handle .btn:active, .ocf-offcanvas .ocfilter-mobile-handle .btn:hover, .ocf-offcanvas .ocfilter-mobile-handle .btn:focus {
    border: none;
    border-radius: 0;
    outline: none;
  }
}

@media (max-width: 1025px) {
  /* menu */
  #megamenu-menu .dropdown:hover .dropdown-menu {
    max-height: initial;
  }
}

@media screen and (max-width: 992px) {
  /* top */
  .header-menu-text {
    display: block;
    color: #000;
  }
  .top-panel a:hover,
  .settings:hover span {
    color: #fff;
  }
  .sidebar-toggle span,
  #search-top span,
  .login-top-mobile span,
  .cart-top-mobile span {
    text-decoration: none;
    color: #000;
  }
  .top-panel-shedule,
  .top-panel-ul,
  .top-panel-div {
    display: none;
  }
  #search-top,
  .cart-top-mobile,
  .login-top-mobile {
    display: block !important;
  }
  .search-box-top input[name=search] {
    color: #000;
  }
  .menu {
    display: none;
  }
  /* sidebar */
  .top-panel .sidebar-toggle {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    padding: 10px 6px;
    float: left;
    width: 40px;
    height: 36px;
    margin: 7px 10px;
    color: #000;
  }
  .top-panel .sidebar-toggle:active, .top-panel .sidebar-toggle:hover, .top-panel .sidebar-toggle:focus {
    color: #F75D4A;
  }
  .top-panel .sidebar-toggle span {
    font-size: 24px;
    font-family: 'themify';
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    text-decoration: none;
  }
  /* menu */
  #header-mobile-menu {
    display: none;
  }
  #header-mobile-menu.mm-spn {
    display: block;
    background: #e2e2e2;
    color: #000;
  }
  .navbar-nav {
    margin: 0;
  }
  /* mmenu */
  .mm-ocd {
    top: 66px;
  }
  .mm-spn--navbar ul.mm-spn--open {
    top: 0 !important;
  }
  .mm-spn--navbar ul.mm-spn--open.mm-spn--parent {
    top: 50px !important;
  }
  .mm-spn ul.mm-spn--open ul.mm-spn--open {
    top: 50px !important;
    background: #e2e2e2;
    z-index: 3;
  }
  .mm-spn.mm-spn--navbar:after {
    color: #000;
  }
  .mm-spn li,
  .mm-spn a {
    background: #e2e2e2;
    color: #000;
  }
  .mm-spn span {
    background: #e2e2e2;
  }
  .mm-spn--custom:before {
    z-index: 2 !important;
  }
  .mm-spn li:after {
    position: absolute;
    z-index: 1;
    left: -19px;
    bottom: 0;
    width: 100%;
    background: #898989;
  }
  /* header */
  #contacts > #phone {
    padding: 0 0 0 18px;
  }
  #contacts > #phone:before {
    left: 0;
    font-size: 19px;
  }
  #contacts > #phone > div {
    margin-bottom: 6px;
  }
  /* search */
  #livesearch_search_results {
    top: 87%;
    left: 0;
    width: 94%;
  }
  /* news */
  .page_oct_blog .row + .content {
    padding: 0 5px;
  }
  .page_oct_blog .top-menu .display {
    width: 52%;
  }
  .page_oct_blog .top-menu .sort {
    width: 34%;
    background: url(../images/sort-arrow-down.png) 89% 59% no-repeat;
  }
  .page_oct_blog .top-menu .show-items {
    width: 14%;
    background: url(../images/sort-arrow-down.png) 90% 59% no-repeat;
  }
  /* category */
  /* manufacturer */
  /* search */
  /* special */
  .top-menu .display {
    width: 52%;
  }
  .top-menu .sort {
    width: 34%;
    background: url(../images/sort-arrow-down.png) 87% 84% no-repeat;
  }
  .top-menu .show-items {
    width: 14%;
    background: url(../images/sort-arrow-down.png) 67% 84% no-repeat;
  }
  .page_manufacturer .top-menu .display,
  .page_search .top-menu .display,
  .page_special .top-menu .display,
  .page_oct_blog .top-menu .display {
    width: 52%;
  }
  .page_manufacturer .top-menu .sort,
  .page_search .top-menu .sort,
  .page_special .top-menu .sort,
  .page_oct_blog .top-menu .sort {
    width: 34%;
    background: url(../images/sort-arrow-down.png) 89% 59% no-repeat;
  }
  .page_manufacturer .top-menu .show-items,
  .page_search .top-menu .show-items,
  .page_special .top-menu .show-items,
  .page_oct_blog .top-menu .show-items {
    width: 14%;
    background: url(../images/sort-arrow-down.png) 90% 59% no-repeat;
  }
  .product-grid > div {
    width: 33.3333%;
  }
  .product-grid .product .image {
    min-height: 240px;
  }
  .product-grid .product .name {
    height: 64px;
  }
  .product-grid .product .price {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .page_manufacturer .top-menu .display,
  .page_search .top-menu .display,
  .page_special .top-menu .display {
    width: 44%;
  }
  .page_manufacturer .top-menu .sort,
  .page_search .top-menu .sort,
  .page_special .top-menu .sort {
    width: 37%;
  }
  .page_manufacturer .top-menu .show-items,
  .page_search .top-menu .show-items,
  .page_special .top-menu .show-items {
    width: 19%;
  }
  /* checkout */
  .page_simplecheckout .top-menu .display {
    width: 100%;
  }
  /* cart */
  .page_checkout .row.checkout_form {
    display: flex;
    flex-direction: column;
  }
  .page_checkout .row.checkout_form .col-md-6.register_block {
    order: 3;
  }
  .page_checkout .row.checkout_form .oct-fastorder-payment.text-right {
    order: 7;
    margin: -20px 0 18px;
  }
  /* information */
  .info-page.content {
    padding: 0;
  }
  /* ocfilter */
  #ocfilter .popover.popover-custom input {
    max-width: 38px;
  }
  /* footer */
  footer {
    margin: 0;
  }
}

@media screen and (max-width: 769px) {
  /* top */
  .top-panel-div,
  .top-panel-ul {
    display: none;
  }
  .currencies .dropdown-menu,
  .languages .dropdown-menu {
    min-width: 46px;
  }
  .currencies .dropdown-menu li,
  .languages .dropdown-menu li {
    margin-left: 0;
  }
  .currencies .dropdown-menu button,
  .languages .dropdown-menu button {
    outline: none;
  }
  .top-panel-shedule {
    display: none;
  }
  /* mobile menu */
  .search-box-top input[name=search]::placeholder {
    color: #000;
  }
  .search-box-top input[type=submit] {
    background: #F75D4A !important;
    color: #000;
  }
  #livesearch_search_results {
    width: 97%;
    left: 1.5%;
    min-height: 300px;
    max-height: 600px;
    overflow-y: scroll;
  }
  #livesearch_search_results img {
    max-width: calc(20% - 8px);
    margin-bottom: 22px;
  }
  #livesearch_search_results li {
    width: 81%;
    font-size: 14px;
  }
  /* mmenu */
  .mm-ocd {
    top: 68px;
  }
  .currencies .dropdown-menu,
  .languages .dropdown-menu {
    min-width: 46px;
  }
  .currencies .dropdown-menu li,
  .languages .dropdown-menu li {
    margin-left: 0;
  }
  .currencies .dropdown-menu button,
  .languages .dropdown-menu button {
    outline: none;
  }
  .top-panel-shedule {
    display: none;
  }
  /* basic */
  #container .breadcrumb {
    padding: 8px 30px 16px;
    font-size: 10px;
  }
  /* header */
  .header_logo {
    margin: 36px auto 43px;
    display: block;
  }
  .header_logo img {
    max-width: 100%;
  }
  #search {
    display: none;
  }
  #contacts {
    display: flex;
    justify-content: flex-start;
    padding: 0 15px 0 35px;
  }
  #contacts > #phone {
    font-size: 16px;
  }
  header.row > div:nth-child(4) {
    display: none;
  }
  /* main */
  .slideshow-box .owl-dots {
    padding-top: 0;
    top: 88%;
  }
  /* category */
  /* manufacturer */
  /* search */
  /* special */
  .page_category .top-menu,
  .page_manufacturer .top-menu,
  .page_search .top-menu,
  .page_special .top-menu {
    margin-bottom: 12px;
  }
  .page_category .top-menu .display,
  .page_manufacturer .top-menu .display,
  .page_search .top-menu .display,
  .page_special .top-menu .display {
    width: 100%;
    margin: 0 0 18px;
  }
  .page_category .top-menu .show-items,
  .page_manufacturer .top-menu .show-items,
  .page_search .top-menu .show-items,
  .page_special .top-menu .show-items {
    display: none;
    background: url(../images/sort-arrow-down.png) 30px 74% no-repeat;
  }
  .page_category .top-menu .sort,
  .page_manufacturer .top-menu .sort,
  .page_search .top-menu .sort,
  .page_special .top-menu .sort {
    position: relative;
    font-size: 0;
    width: 202px;
    padding: 0 !important;
    margin: 0 22px 0 0 !important;
    background: rgba(218, 218, 218, 0.2);
    border-radius: 0;
  }
  .page_category .top-menu .sort #input-sort,
  .page_manufacturer .top-menu .sort #input-sort,
  .page_search .top-menu .sort #input-sort,
  .page_special .top-menu .sort #input-sort {
    width: auto;
    max-width: 218px;
    padding: 9px 0 9px 32px;
    margin: 0;
    font-size: 14px;
    color: #000 !important;
    border: none;
  }
  .page_category .top-menu .sort:before,
  .page_manufacturer .top-menu .sort:before,
  .page_search .top-menu .sort:before,
  .page_special .top-menu .sort:before {
    position: absolute;
    top: 50%;
    left: 8px;
    transform: translateY(-50%);
    content: "\e9088";
    background: none;
    color: #000;
    font-size: 14px;
    font-family: "icomoon", sans-serif;
  }
  .page_category [name="category_id"],
  .page_manufacturer [name="category_id"],
  .page_search [name="category_id"],
  .page_special [name="category_id"] {
    margin: 8px 0 12px;
  }
  .product-grid .product .image {
    margin-bottom: 100px;
  }
  .product-grid .product .product-about {
    left: 0;
    bottom: 0;
    padding-top: 2px;
  }
  .product-grid .product .product-about .product-buttons {
    display: block;
  }
  .product-grid .product .product-about .product-buttons .cart {
    opacity: 1;
    width: 108px !important;
  }
  .product-grid .product .product-about .product-buttons .buyByOneClick {
    padding: 6px 4px;
  }
  .product-grid .price {
    padding-bottom: 12px;
  }
  .top-menu-filter-link {
    display: block;
    position: relative;
    padding-left: 20px;
    font-size: 14px;
    color: #000 !important;
  }
  .top-menu-filter-link i {
    position: absolute;
    top: 2px;
    left: 1px;
    font-size: 14px;
    font-weight: 900;
  }
  .top-menu-filter-link:hover, .top-menu-filter-link:focus {
    text-decoration: none;
  }
  /* category */
  .page_category #container .content {
    margin-left: 0;
    margin-right: 0;
  }
  .page_category .row + .content {
    margin: 0 5px;
  }
  .page_category article#content {
    padding: 0 20px;
  }
  /* manufacturer */
  .manufacturer-list .manufacturer-content {
    flex-flow: row wrap;
    width: 82%;
  }
  .manufacturer-list .manufacturer-content ul {
    width: 50%;
    margin-left: 0;
  }
  /* product */
  .page_product article#content {
    padding: 0 15px;
  }
  .product-info .btn-plus button:after {
    top: 47%;
    left: 50%;
  }
  .product-info .btn-minus button:before {
    background: url(../images/minus-sign.svg) no-repeat 55.5% center;
    background-size: 52%;
  }
  .product-info .btn-minus button:after {
    top: 47%;
    left: 53%;
  }
  .tabs-box .nav > li > a {
    padding: 10px 15px;
  }
  table#kit-series {
    font-size: 14px;
  }
  table#kit-series td.sku {
    display: none;
  }
  #kit-series .image {
    display: none;
  }
  #kjseries button.btn-primary {
    width: 86px;
  }
  td.btn_buy_text {
    padding: 8px 0 !important;
  }
  /* news */
  .page_oct_blog .top-menu .show-items {
    background: url(../images/sort-arrow-down.png) 32px 74% no-repeat;
  }
  .page_oct_blog .top-menu .sort {
    background: url(../images/sort-arrow-down.png) 155px 74% no-repeat;
  }
  /* information */
  .page_information article#content {
    padding: 0 20px;
  }
  /* contact */
  .page_contact article#content {
    padding: 0;
  }
  /* reviews */
  .page_sreview_reviews article#content {
    padding: 0 20px;
  }
  /* account */
  .page_account article#content {
    padding: 0 20px;
  }
  ul.account-ul-last {
    display: table;
  }
  /* checkout */
  .check-content {
    padding: 0 20px;
  }
  /* copyright */
  #powered .copyright .copyright__company, #powered .copyright .copyright__creator {
    width: 100%;
  }
  /* ocfilter */
  #ocfilter {
    padding: 0;
  }
  .ocf-offcanvas .ocf-offcanvas-body {
    top: 5px;
  }
  #ocfilter .selected-options .ocfilter-option {
    padding: 4px 0 4px 6px;
  }
  #ocfilter .selected-options .ocfilter-option:last-of-type {
    padding: 0 0 4px 6px;
  }
  .popover-content .form-inline {
    padding-left: 10px;
  }
  #ocfilter .popover.popover-custom input {
    max-width: 40px;
    min-width: 40px;
  }
  #ocfilter .ocfilter-option.clicked .ocf-option-values {
    padding: 0 10px 0 0;
    overflow-x: hidden;
  }
}

@media screen and (max-width: 580px) {
  /* header */
  header.row {
    flex-direction: column-reverse;
    padding: 14px 0 4px;
  }
  .logo-container {
    width: 100%;
    padding: 0;
  }
  .col-cart {
    display: none;
  }
  #contacts {
    display: none !important;
  }
  /* main */
  .slideshow-box .owl-dots {
    padding-top: 0;
    top: 85%;
  }
  /* category */
  .top-menu-filter {
    display: block;
  }
  .product-grid > div {
    width: 50%;
  }
  /* copyright */
  #powered .copyright .copyright__company,
  #powered .copyright .copyright__creator {
    width: 100%;
    text-align: left;
  }
}

@media screen and (max-width: 480px) {
  /* basic */
  #container .breadcrumb {
    display: none;
  }
  /* header */
  .header_logo img {
    max-width: 100%;
  }
  /* search */
  #livesearch_search_results img {
    max-width: calc(30% - 8px);
  }
  #livesearch_search_results li {
    width: 70%;
    margin-top: 0;
  }
  /* main */
  .slideshow-box .owl-dots {
    top: 80%;
  }
  .home #content .box-main {
    padding: 0 15px;
  }
  .item .price {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .category-slide .item .name {
    height: 72px;
  }
  .product-grid .product .product-buttons {
    padding: 0;
  }
  .product-grid .product .product-about .product-buttons .cart {
    width: 130px !important;
  }
  #cat-bestseller .item .rating,
  #cat-latest .item .rating,
  [id^=cat-feautured-] .item .rating {
    bottom: 182px;
  }
  #cat-bestseller .item .image > a:before,
  #cat-latest .item .image > a:before,
  [id^=cat-feautured-] .item .image > a:before {
    display: none;
  }
  #cat-bestseller .item a.out-of-stock-button,
  #cat-latest .item a.out-of-stock-button,
  [id^=cat-feautured-] .item a.out-of-stock-button {
    visibility: visible;
  }
  #cat-latest .item,
  #cat-bestseller .item,
  [id^=cat-feautured-] .item {
    padding: 0;
  }
  #cat-bestseller .item .cart,
  #cat-latest .item .cart,
  [id^=cat-feautured-] .item .cart {
    width: 112px;
  }
  #cat-bestseller .item .cart .button-cart,
  #cat-latest .item .cart .button-cart,
  [id^=cat-feautured-] .item .cart .button-cart {
    width: 100%;
  }
  #cat-bestseller .item .image img,
  #cat-latest .item .image img,
  [id^=cat-feautured-] .item .image img {
    width: 100%;
  }
  #cat-bestseller .item a.out-of-stock-button,
  #cat-latest .item a.out-of-stock-button,
  [id^=cat-feautured-] .item a.out-of-stock-button {
    width: 112px;
  }
  /* category */
  .top-menu .sort {
    width: 160px;
  }
  .top-menu .sort #input-sort {
    max-width: 160px;
  }
  .product-grid .product .product-buttons {
    padding: 0;
  }
  .product-grid .product .product-about .product-buttons .cart {
    width: 130px !important;
  }
  .product-grid .product .product-about .product-buttons .buyByOneClick {
    padding: 6px;
  }
  .product-grid .product .name {
    height: 80px;
  }
  .product-grid .rating {
    left: 50%;
    transform: translateX(-50%);
  }
  /* manufacturer */
  .page_manufacturer .top-menu {
    margin-bottom: 0;
  }
  .manufacturer-list .manufacturer-heading {
    margin-right: 30px;
  }
  .manufacturer-list .manufacturer-content ul {
    width: 100%;
  }
  /* product */
  #cat-bestseller .item .cart .buyByOneClick,
  #cat-latest .item .cart .buyByOneClick,
  [id^=cat-feautured-] .item .cart .buyByOneClick {
    padding: 6px 4px;
  }
  /* button to top */
  #back-top {
    left: 24px;
    bottom: 72px;
    z-index: 99;
  }
  /* ocfilter */
  .ocf-offcanvas .ocf-offcanvas-body {
    top: 0;
  }
  #ocfilter .list-group-item.ocfilter-option-custom {
    max-height: 31px;
    margin: 4px 0 3px;
  }
  #ocfilter .list-group-item.ocfilter-option-custom:after {
    bottom: -6px;
  }
  #ocfilter .list-group-item.ocfilter-option-custom .popover-custom .popover-title {
    padding: 12px 13px 5px 6px;
  }
  #ocfilter .list-group-item.ocfilter-option-custom .popover-title:before {
    top: 11px;
  }
  #ocfilter .list-group-item.ocfilter-option-custom .popover-title:after {
    top: 5px;
  }
  #ocfilter .list-group-item.ocfilter-option-custom .ocf-option-values .noUi-target {
    margin: 4px 15px 12px 15px;
  }
}

@media screen and (max-width: 376px) {
  /* category */
  .page_category .top-menu .sort,
  .page_manufacturer .top-menu .sort,
  .page_search .top-menu .sort,
  .page_special .top-menu .sort {
    width: 160px;
  }
  .page_category .top-menu .sort #input-sort,
  .page_manufacturer .top-menu .sort #input-sort,
  .page_search .top-menu .sort #input-sort,
  .page_special .top-menu .sort #input-sort {
    max-width: 160px;
  }
}

@media screen and (max-width: 360px) {
  /* category */
  .product-grid .product .product-about .product-buttons .cart {
    width: 108px !important;
  }
  /* product */
  .productAttributes {
    font-size: 13px;
  }
  .tabs-box .nav > li > a {
    font-size: 13px;
  }
  .tab-content p {
    font-size: 13px;
  }
}

/*__________ END DESKTOP FIRST __________ */
/*__________ TODO MOBILE FIRST __________ */
@media screen and (min-width: 992px) {
  /* menu */
  #stick_menu.to_top .menu__wrapperStickMenu {
    width: 970px;
    min-width: 320px;
    max-width: 1170px;
    padding: 0 15px;
    margin: 0 auto;
  }
  .to_top {
    top: 0;
    right: 0;
    left: 0;
    margin-top: 0;
  }
  .to_top #megamenu-menu .dropdown:hover .dropdown-menu {
    left: 0 !important;
  }
  .navbar-nav {
    position: relative;
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  #megamenu-menu .dropdown:hover .dropdown-menu {
    width: 100%;
  }
  #megamenu-menu .megamenu-type-category-full .megamenu-parent-block {
    display: inline-block;
    margin-left: 10px;
    margin-top: 0;
    margin-bottom: 0;
    vertical-align: top;
    min-height: 159px;
    width: 120px;
    border-bottom: 1px solid #eee;
  }
  #megamenu-menu .megamenu-type-category-full .megamenu-parent-title {
    border: none;
    display: block;
    padding: 5px;
    margin-top: auto;
    text-decoration: none;
    font-weight: 700;
    font-size: 14px;
  }
  #megamenu-menu .dropdown:hover .dropdown-menu {
    top: 24px;
  }
  #megamenu-menu .megamenu-type-manufacturer a.megamenu-parent-title:hover {
    color: #9C9C9C !important;
    text-decoration: none;
  }
  .dropdown-menu.megamenu-type-manufacturer {
    padding: 13px 0 5px;
  }
  .navbar-nav:before {
    display: block !important;
    content: '';
    position: absolute;
    bottom: -1px;
    left: 0;
    background: linear-gradient(90deg, #fff, #000);
    width: 50%;
    height: 2px;
  }
  .navbar-nav:after {
    display: block !important;
    content: '';
    position: absolute;
    bottom: -1px;
    left: 49%;
    background: linear-gradient(90deg, #000, #fff);
    width: 51%;
    height: 2px;
  }
}

@media screen and (min-width: 1200px) {
  /* menu */
  #stick_menu.to_top .menu__wrapperStickMenu {
    width: 100%;
  }
  /* category */
  .product-grid .product .product-buttons .cart {
    width: 130px !important;
  }
}

@media screen and (min-width: 768px) and (max-width: 992px) {
  /* basic */
  #container .breadcrumb {
    padding: 8px 16px 16px;
  }
  /* header */
  #search.clearfix {
    margin-bottom: 13px;
    padding: 0;
  }
  .search__slogan {
    font-size: 11px;
  }
  #contacts {
    padding-left: 14px;
    padding-right: 7px;
  }
  #contacts > #phone {
    font-size: 13px;
  }
  .col-cart {
    padding-left: 0;
  }
  #right-block i {
    margin-right: 9px;
    font-size: 20px;
  }
  #right-block #cart {
    padding: 6px 10px;
  }
  /* menu fix */
  #megamenu-menu .nav > li:first-child > a {
    padding: 5px 15px !important;
  }
  #megamenu-menu .nav > li:first-child > a.megamenu-notoggle-a.current-link {
    padding: 0 !important;
  }
  #megamenu-menu .btn-navbar {
    margin-left: 0;
  }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
  /* menu */
  #megamenu-menu .nav > li:first-child > a {
    padding: 0 5px 0 8px !important;
  }
  #megamenu-menu .nav > li > a {
    padding: 0 5px 0 5px !important;
    font-size: 15px;
  }
  #megamenu-menu .nav > li:last-child > a {
    padding: 0 7px 0 6px !important;
  }
  /* category */
  .product-grid .product .product-buttons .cart {
    width: 120px !important;
  }
  /* latest */
  .item .name a {
    font-size: 13px;
  }
}

@media only screen and (max-width: 1199px) {
  .top-panel-div-span {
    display: inline-block;
  }
}

@media screen and (max-width: 1199px) and (min-width: 992px) {
  /* menu */
  #megamenu-menu .nav > li:first-child > a {
    padding: 6px 6px 2px 12px !important;
  }
  #megamenu-menu .nav > li:last-child > a {
    padding: 6px 6px 2px 12px !important;
  }
  #megamenu-menu .nav > li > a {
    padding: 6px 6px 2px 12px !important;
  }
  #megamenu-menu .nav > li > a:before {
    top: 12px;
  }
  /* basic */
  #container .breadcrumb {
    padding: 8px 14px 16px;
  }
  /* news */
  .info-page.content {
    padding: 0 6px;
  }
}

@media only screen and (max-device-width: 5120px) and (min-device-width: 1600px) {
  /* modal */
  #partcont {
    max-width: 570px;
  }
}

/*__________ END MOBILE FIRST __________ */
