/*=Font size */
.ocfilter-option .ocf-option-name,
.ocfilter-option .ocf-option-values,
.ocfilter-option .ocf-option-description,
.ocfilter-option .fields-price {
	font-size: 12px;
}

.ocfilter-option .ocf-option-name {
	font-size: 12px;
	font-weight: 600;
	margin-bottom: 10px;
}

.ocfilter-option .ocf-option-values {
	overflow: visible;
}

.list-group-item {
	overflow: visible!important;
}

.ocfilter-option .ocf-option-values .dropdown-menu {
	min-width: 100%;
	max-height: 250px;
	overflow: auto;
  padding: 10px;
}

.ocfilter-option .ocf-color {
	border-radius: 3px 3px 3px 3px;
	float: left;
	height: 19px;
	margin: 1px -16px 0 -3px;
	width: 19px;
}

.ocfilter-option .ocf-image {
	background-repeat: no-repeat;
	background-position: 0 0;
	float: left;
	height: 19px;
	margin: 1px -16px 0 -3px;
	position: relative;
	width: 19px;
	z-index: 10;
}

.ocfilter-option label {
  cursor: pointer;
  display: block;
  font-size: 12px;
  font-weight: normal;
  line-height: 1.2;
  white-space: normal;
  padding: 4px 0;
  width: 100%;
}

.ocfilter-option .dropdown-menu label{
  white-space: nowrap;
}

.ocfilter-option label input {
	display: inline-block;
	margin: 0 5px 0 0;
	vertical-align: top;
}

.ocfilter-option label small {
	background-color: #DBEAF9;
	color: #0E6A93;
	font-weight: 200;
	font-size: 10px;
	margin-left: 5px;
}

.ocfilter-option label.disabled {
	color: #999999;
}

.ocfilter-option label.disabled small {
	background-color: #EFEFEF;
	color: #999999;
}

.ocfilter-option label {
	color: #444;
	text-decoration: none;
}

.ocfilter-option label.ocf-selected {
	color: #E56101;
}

.ocfilter-option label.ocf-selected small {
	opacity: 0;
}

/*=Collapse Options */
button[data-target="#ocfilter-hidden-options"] {
	border-top-left-radius: 0;
	border-top-right-radius: 0;
}

button[data-target="#ocfilter-hidden-options"] .fa:before {
	content: "\f078";
}

button[data-target="#ocfilter-hidden-options"][aria-expanded="true"] {
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
}

button[data-target="#ocfilter-hidden-options"][aria-expanded="true"] .fa:before {
	content: "\f077";
}

/*=Collapse Values */
.collapse-value {
	margin-bottom: 10px;
}

.collapse-value > button, .collapse-value > button:focus, .collapse-value > button:active, .collapse-value > button:active:focus {
	text-align: left;
	background: transparent;
	border: none;
	box-shadow: none;
	padding: 0;
	font-size: 12px;
	text-decoration: underline;
	outline: none;
}

.collapse-value > button .fa:before {
	content: "\f107";
}

.collapse-value > button[aria-expanded="true"] {
}

.collapse-value > button[aria-expanded="true"] .fa:before {
	content: "\f106";
}

/*=Selected Options */
.selected-options {
	color: #333333;
	padding: 0;
}

.selected-options .ocfilter-option {
	background-color: #F8F8F8;
	font-size: 10px;
	padding: 4px 5px;
}

/*=Price filtering */
#ocfilter-price .ocf-option-values {
	padding: 10px 10px 5px;
}

.trackbar, .trackbar .left-block, .trackbar .right-block {
	height: 4px;
}

.popover-content .form-inline {
	white-space: nowrap;
}

.popover-content .form-inline .form-group {
	display: inline-block;
	margin-bottom: 0;
	vertical-align: middle;
}

.scale {
	margin-bottom: 35px;
	margin-left: 15px;
	margin-right: 15px;
}

.ocfilter .popover .form-inline .form-control {
	width: 100px;
}

/* Position center style */
.ocfilter-content-top, .ocfilter-content-bottom {
}

.ocfilter-content-top .box-content, .ocfilter-content-bottom .box-content {
	font-size: 0;
}

.ocfilter-content-top .ocfilter-option, .ocfilter-content-bottom .ocfilter-option {
	display: inline-block;
	vertical-align: top;
	width: 33%;
}

.ocfilter-content-top .ocfilter-option + .ocfilter-option, .ocfilter-content-bottom .ocfilter-option + .ocfilter-option {
	border-top: none;
}

/*=Mobile */
.ocf-offcanvas{
  position: fixed;
  top: 0;
  background-color: #fff;
  bottom: 0;
  left: -250px;
  z-index: 1000;
  height: 100%;
  width: 250px;
  padding: 0;

  -webkit-transition: left 250ms ease;
  -moz-transition: left 250ms ease;
  -ms-transition: left 250ms ease;
  -o-transition: left 250ms ease;
  transition: left 250ms ease;
}
.ocf-offcanvas.active{left:0;}
.ocf-offcanvas .ocfilter-mobile-handle{position: absolute; left: 100%; top: 88px;}
.ocf-offcanvas .ocfilter-mobile-handle .btn{border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}
.ocf-offcanvas .ocf-offcanvas-body{
overflow-x: hidden;
    overflow-y: auto;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}
.ocf-offcanvas .panel{}
.ocf-offcanvas{}
.ocf-offcanvas{}